import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { User } from "firebase/auth"
import { Grid } from "react-loader-spinner"
import NavigationHeader from "../Navigation/NavigationHeader"
import NavigationFooter from "../Navigation/NavigationFooter"
import { BASE_FETCH_URL } from "../../App"
import { useUserValue } from "../Auth/UserContext"
import ChatbotChatbox, { ChatMessage, ChatbotSource } from "./ChatbotChatbox"
import ChatbotSettings from "./ChatbotSettings"
import ChatbotEmbed from "./ChatbotEmbed"
import ChatbotAnalytics from "./ChatbotAnalytics"
import MessageDashboard from "./ChatbotDashboard"

export type TabName = 'Chatbot' | 'Settings' | 'Analytics' | 'Dashboard' | 'Embed'

const tabs: TabName[] = [
  'Chatbot',
  'Settings',
  'Dashboard',
  'Analytics',
  'Embed'
]

export type ChatbotSettingsResponse = {
  chatbotId: string
  chatbotName: string
  chat_window_display_name: string
  initial_message: string
  base_prompt: string
  model: GptModel
}

export const parseIntroMessage = (unparsedIntroMessage: string): ChatMessage[] => {
  return unparsedIntroMessage.split(/\r?\n/).map(msg => (
    { agent: 'bot', content: msg }
  ))
}

// default first message to display in UI (not necessary to define the prompt)
export const DEFAULT_INTRO_MESSAGE = 'Hi! I am a friendly AI assistant. Ask me anything!'

export const DEFAULT_BASE_PROMPT = 'I want you to act as a friendly AI assistant providing answers for questions I have about a document based on the context provided. If you cannot find the answer, say exactly "Hmm, I am not sure." and stop after that. Do not answer questions not directly related to the context. Never break character.'

export type GptModel = 'gpt-3.5-turbo' | 'gpt-4'
export const models: GptModel[] = [
  'gpt-3.5-turbo',
  'gpt-4'
]
export function Chatbot() {
  const { userAuth } = useUserValue()
  const { chatbotId } = useParams()
  const [loading, setLoading] = useState(true)
  const [activeTab, setActiveTab] = useState<TabName>(tabs[0])
  const [accessForbidden, setAccessForbidden] = useState(false)

  // settings
  const [name, setName] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [unparsedIntroMessage, setUnparsedIntroMessage] = useState('')
  const [basePrompt, setBasePrompt] = useState('')
  const [model, setModel] = useState(models[0])
  const [settingsError, setSettingsError] = useState<string | null>(null)

  // analytics
  // const [totalConversations] = useState(0)
  // const [avgMsgsPerConversation] = useState(0)

  // chatbox display
  const [remainingMessagesCount, setRemainingMessagesCount] = useState<number | null>(null)
  const [source, setSource] = useState<ChatbotSource[] | null>(null)

  const fetchChatbotSettings = useCallback(async (user: User) => {
    try {
      const token = await user.getIdToken()
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          chatbotId: chatbotId,
        }),
      }
      const response = await fetch(`${BASE_FETCH_URL}/api/v1/get-chatbot-settings`, requestOptions)
      if (!response.ok) {
        if (response.status === 403) {
          setAccessForbidden(true)
        } else {
          throw new Error(response.status + ": " + response.statusText)
        }
      }
      const settings: ChatbotSettingsResponse = await response.json()
      setName(settings.chatbotName ?? '')
      setDisplayName(settings.chat_window_display_name ?? '')
      setUnparsedIntroMessage(settings.initial_message ?? DEFAULT_INTRO_MESSAGE)
      setBasePrompt(settings.base_prompt || DEFAULT_BASE_PROMPT)
      setModel(settings.model ?? models[0])
      setLoading(false)
    } catch (e: any) {
      setUnparsedIntroMessage(DEFAULT_INTRO_MESSAGE)
      setBasePrompt(DEFAULT_BASE_PROMPT)
      setModel(models[0])
      setSettingsError(`${e}`)
      setLoading(false)
    }
  }, [chatbotId])

  useEffect(() => {
    if (userAuth) {
      fetchChatbotSettings(userAuth)
    }
  }, [chatbotId, userAuth, fetchChatbotSettings])

  const renderActiveTabContent = (tab: TabName) => {
    switch (tab) {
      case 'Chatbot':
        return (
          <div>
            <div className="h-[550px] rounded-md border border-slate-300">
              <ChatbotChatbox introMessages={parseIntroMessage(unparsedIntroMessage)} displayName={displayName}
                chatbotId={chatbotId!} setSource={setSource} setRemainingMessagesCount={setRemainingMessagesCount} />
            </div>
            {remainingMessagesCount && <RemainingMessagesDisplay count={remainingMessagesCount} />}
            {source && <SourceDisplay source={source} />}
          </div>
        )
      case 'Settings':
        return (
          <ChatbotSettings
            userAuth={userAuth}
            unparsedIntroMessage={unparsedIntroMessage}
            setUnparsedIntroMessage={setUnparsedIntroMessage}
            displayName={displayName}
            setDisplayName={setDisplayName}
            name={name}
            setName={setName}
            chatbotId={chatbotId!}
            basePrompt={basePrompt}
            setBasePrompt={setBasePrompt}
            setActiveTab={setActiveTab}
            settingsError={settingsError}
            model={model}
            setModel={setModel}
          />
        )
      case 'Analytics':
        return (
          <ChatbotAnalytics
            // totalConversations={totalConversations}
            // avgMsgsPerConversation={avgMsgsPerConversation}
            userAuth={userAuth}
            chatbotId={chatbotId!}
          />
        )
        case 'Dashboard':
          return (
            <MessageDashboard
            userAuth={userAuth}
            chatbotId={chatbotId!}
            chatbotName={displayName!}
            />
          )
      case 'Embed':
        return <ChatbotEmbed userAuth={userAuth!} chatbotId={chatbotId!} />
    }
  }


  return (
    <>
      <div className="min-h-screen flex flex-col px-8">
        <NavigationHeader />
        <div className="flex flex-col flex-1 w-screen max-w-4xl self-center px-8">
          {loading ? <Grid
            height="80"
            width="80"
            color="#7398E8"
            ariaLabel="loading"
            radius="12"
            wrapperClass="self-center mt-56"
            visible
          /> : accessForbidden ?
            <div>
              <h1 className="mt-56 text-3xl text-center font-bold tracking-tight mb-4">This chatbot is private.</h1>
              <p className="text-md text-center text-slate-500">You do not have the right permissions to access this chatbot.</p>
            </div>
            :
            <>
              <h1 className="pb-2 text-xl tracking-tight font-medium text-center">{name ? name : chatbotId}</h1>
              <div className="flex flex-row justify-around pb-2">
                {tabs.map(tab => (
                  <button className={`h-min text-xs md:text-sm px-4 py-1.5 ${tab === activeTab ?
                    "border-b border-b-1 border-b-accent-blue" : ""}`} onClick={() => setActiveTab(tab)} key={tab}>{tab}</button>
                ))}
              </div>
              {renderActiveTabContent(activeTab)}
            </>}
        </div>
      </div>
      <NavigationFooter />
    </>
  )
}

export function SourceDisplay({ source }: { source: ChatbotSource[] }) {
  return <div className="mb-8">{source.map((e) => <div key={e.query}>
    {e.results.map(result => (
      <p className="text-slate-400 text-xs border-t-slate-200 border-t pt-2 mt-6" key={result.id}>{result.text}</p>
    ))}
  </div>
  )}</div>

}

export function RemainingMessagesDisplay({ count }: { count: number }) {
  return <p className="pt-2 text-xs text-slate-400 tracking-tight italic mb-4">{count} message credits remaining</p>

}


