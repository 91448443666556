/* eslint-disable no-unused-vars */
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth"
import { useNavigate } from "react-router-dom"
import { logEvent } from "firebase/analytics"
import { analytics } from '../Auth/firebase'
import { auth } from '../Auth/firebase'
import NavigationHeader from '../Navigation/NavigationHeader'
import NavigationFooter from '../Navigation/NavigationFooter'


// we don't do anything in this file after redirect, 
// since App.tsx listens for onAuthStateChanged and sets the AuthContext there
function handleLogin({ navigate }: any) {
  const provider = new GoogleAuthProvider()
  provider.setCustomParameters({
    prompt: 'select_account',
  })
  signInWithPopup(auth, provider)
    .then(() => navigate('/'))
  logEvent(analytics, 'success_login')
}

function Login() {
  const navigate = useNavigate()

  return (
    <div>
      <div className="min-h-screen flex flex-col px-8">
        <NavigationHeader />
        <div className="flex flex-col flex-1 items-center justify-evenly">
          <div className="flex flex-col items-center">
            <h1 className="text-5xl font-bold tracking-tight my-4">Log in</h1>

            <button className="bg-black  rounded-md shadow-md py-4 px-5 mt-6 flex items-center" onClick={() => handleLogin({ navigate })}>
              <img className="w-6 h-6" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" />
              <p className="pl-4 text-md text-white tracking-tight">Sign in with Google</p></button>
          </div>
          {/* empty div to evenly justify content above against */}
          <div />
        </div>
      </div>
      <NavigationFooter />
    </div>
  )
}

export default Login