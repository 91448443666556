import { signOut } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { useUserValue } from '../Auth/UserContext'
import { auth } from '../Auth/firebase'
import NavigationHeader from '../Navigation/NavigationHeader'
import NavigationFooter from '../Navigation/NavigationFooter'

function capitalizeFirstLetter(str?: string) {
  if (!str) return ''

  const firstLetter = str.charAt(0)
  const firstLetterCap = firstLetter.toUpperCase()
  const remainingLetters = str.slice(1)

  return firstLetterCap + remainingLetters
}


function Account() {
  const navigate = useNavigate()
  const { userAuth, userSettings } = useUserValue()

  if (!userAuth) {
    navigate('/login')
  }

  return (
    <div>
      <div className="min-h-screen flex flex-col px-8">
        <NavigationHeader />
        <div className="flex flex-col flex-1 items-center justify-evenly">
          <div className="flex flex-col w-full max-w-sm">

            <h1 className="text-5xl font-bold tracking-tight my-8 self-center">Account</h1>
            <div className="flex flex-col rounded-lg shadow-md border border-slate-100 p-6">

              <p className="text-xs text-slate-400 tracking-tight font-medium pb-1">Email:</p>
              <p className="text-md pb-3">{userAuth?.email}</p>

              <p className="text-xs text-slate-400 tracking-tight font-medium pb-1">Plan:</p>
              <p className="text-md pb-3">{capitalizeFirstLetter(userSettings?.pricingTier)}</p>

              <p className="text-xs text-slate-400 tracking-tight font-medium pb-1">Message credits:</p>
              <p className="text-md pb-3">{`${userSettings?.remainingMessages ?? "-"}`}</p>

              {userAuth?.metadata.creationTime &&
                <>
                  <p className="text-xs text-slate-400 tracking-tight font-medium pb-1">Account created on:</p>
                  <p className="text-md pb-3">{`${new Date(userAuth?.metadata.creationTime).toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" })}`}</p>
                </>}

              {userAuth?.metadata.lastSignInTime &&
                <>
                  <p className="text-xs text-slate-400 tracking-tight font-medium pb-1">Last sign in:</p>
                  <p className="text-md pb-6">{`${new Date(userAuth?.metadata.lastSignInTime).toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" })}`}</p>
                </>}

              <button
                className="bg-black text-white text-md tracking-tight p-2 h-fit w-full rounded-lg shadow-md"
                onClick={() => {
                  signOut(auth).then(() => navigate('/login'))
                }}>
                Sign Out
              </button>

            </div>
          </div>
          {/* empty div to evenly justify content above against */}
          <div />
        </div>
      </div>
      <NavigationFooter />
    </div>
  )
}

export default Account