import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { NavLink, useLocation } from 'react-router-dom'
import { useUserValue } from '../Auth/UserContext'

const screenTabs = [
  { title: 'Demo', slug: '/' },
  // { title: 'Featured Chatbots', slug: '/featured-chatbots' },
  { title: 'My Chatbots', slug: '/my-chatbots' },
  { title: 'How it Works', slug: '/how-it-works' },
  { title: 'Pricing', slug: '/pricing' }
]

const determineActiveRoute = (path: string, slug: string) => {
  return slug === '/my-chatbots' ? path === slug || path === '/new-chatbot' || path.startsWith('/chatbot')
    : slug === '/featured-chatbots' ? path === slug || path.startsWith('/featured-chatbots')
      : path === slug
}

const NavigationHeader = () => {
  const location = useLocation()
  const { userAuth } = useUserValue()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <header className="no-underline">
      {/* full screen nav */}
      <nav className="flex flex-row justify-between py-4" aria-label="Global">
        <NavLink to="/" className="self-center flex text-lg font-medium">
          CHATQ
        </NavLink>
        <div className="flex items-center">
          <div className="flex lg:hidden">
            <button
              type="button"
              className="px-0"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex">
            {screenTabs.map(screen => {
              const isActiveRoute = determineActiveRoute(location.pathname, screen.slug)
              return (
                <NavLink key={screen.slug} className={`mx-3 px-2 py-2 text-slate-900 text-md tracking-tight ${isActiveRoute ? "font-semibold" : ""}`} to={screen.slug}>
                  {screen.title}
                </NavLink>
              )
            })}
          </div>
          <div className="hidden lg:flex">
            {userAuth ? (
              <NavLink to="/account" className={`bg-gradient-to-tl from-accent-turquoise to-accent-blue text-white ml-3 px-4 py-1.5 rounded-lg text-md tracking-tight ${location.pathname === '/account' ? "font-semibold" : ""}`}>
                Account <span aria-hidden="true">&rarr;</span>
              </NavLink>
            ) : (
              <>
                <NavLink to="/login" className={`bg-gradient-to-tl from-accent-turquoise to-accent-blue text-white ml-3 px-4 py-1.5 rounded-lg text-md tracking-tight ${location.pathname === '/login' ? "font-semibold" : ""}`}>
                  Log in <span aria-hidden="true">&rarr;</span>
                </NavLink>
              </>
            )}
          </div>
        </div>
      </nav>

      {/* mobile nav */}
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white border-l border-l-slate-100 p-4 sm:max-w-sm">
          <div className="flex items-center justify-between">
            <p className="text-xl font-semibold">
              ChatQ
            </p>
            <button
              type="button"
              className="px-0"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-4">
                {screenTabs.map(screen => {
                  const isActiveRoute = determineActiveRoute(location.pathname, screen.slug)
                  return (
                    <NavLink
                      key={screen.slug}
                      to={screen.slug}
                      onClick={() => setMobileMenuOpen(false)}
                      className={`block py-2 text-slate-900 text-md tracking-tight ${isActiveRoute ? "font-semibold" : ""}`}
                    >
                      {screen.title}
                    </NavLink>
                  )
                })}
              </div>
              <div className="py-6">
                {userAuth ? (
                  <NavLink
                    to="/account"
                    onClick={() => setMobileMenuOpen(false)}
                    className={`bg-gradient-to-tl from-accent-turquoise to-accent-blue text-white px-4 py-1.5 my-2 rounded-lg text-md tracking-tight ${location.pathname === `/account` ? "font-semibold" : ""}`}>
                    Account <span aria-hidden="true">&rarr;</span>
                  </NavLink>
                ) : (
                  <NavLink
                    to="/login"
                    onClick={() => setMobileMenuOpen(false)}
                    className={`bg-gradient-to-tl from-accent-turquoise to-accent-blue text-white px-4 py-1.5 my-2 rounded-lg text-md tracking-tight ${location.pathname === '/login' ? "font-semibold" : ""}`}>
                    Log in <span aria-hidden="true">&rarr;</span>
                  </NavLink>
                )}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}

export default NavigationHeader
