import { useState, useEffect, useCallback } from 'react'
import { User } from "firebase/auth"
import { Grid } from "react-loader-spinner"
import ChatbotChatbox from "../Chatbot/ChatbotChatbox"
import { BASE_FETCH_URL } from "../../App"
import {ChatMessage} from "./ChatbotChatbox"


interface Conversation {
    id: string 
    timestamp: string
    messages: ChatMessage[]
}

type MessageDashboardProps = {
    userAuth: User | null
    chatbotId: string
    chatbotName: string
}


const MessageDashboard = ({userAuth, chatbotId, chatbotName}: MessageDashboardProps) => {
    // Sample agent for the hardcoded messages
    // const sampleAgent: Agent = { id: 'agent1', name: 'Agent Smith' } 
    const [conversations, setConversations] = useState<Conversation[]>([])
    const [selectedConversation, setSelectedConversation] = useState<Conversation | null>(null)
    const [selectedConversationId, setSelectedConversationId] = useState<string | null>(null)
    const [fetchError, setFetchError] = useState<string | null>(null)
    const [isLoading, setIsLoading] = useState(true)


    const loadConversations = useCallback(async () => {
        setIsLoading(true)
        try {
            const token = await userAuth?.getIdToken() 
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ chatbotId }),
            } 
    
            const response = await fetch(`${BASE_FETCH_URL}/api/v1/get-chatbot-conversations`, requestOptions) 
            setIsLoading(false)
            if (!response.ok) {
                throw new Error(`${response.status}: ${response.statusText}`) 
            }
    
            const conversationsResponse = await response.json() 
            console.log('Conversations Response:', conversationsResponse) 
    
            // Parse and transform the conversations data to match the Conversation interface
            const fetchedConversations: Conversation[] = Object.entries(conversationsResponse.conversations).map(([id, conversationData]) => {
                const data = conversationData as Conversation
            
                // Sort messages by timestamp in ascending order
                const sortedMessages = data.messages
                    .filter(message => message.timestamp) // Filter out messages without a timestamp
                    .sort((a, b) => {
                        const dateA = new Date(a.timestamp as string)
                        const dateB = new Date(b.timestamp as string)
                        return dateA.getTime() - dateB.getTime()
                    })
            
                // Determine the conversation timestamp
                // Default to an empty string if no valid timestamp is found
                const conversationTimestamp = sortedMessages.length > 0 
                    ? sortedMessages[sortedMessages.length - 1].timestamp
                    : ''
            
                return {
                    id,
                    timestamp: conversationTimestamp || '', // Fallback to an empty string if undefined
                    messages: sortedMessages
                }
            })

            // Sort conversations by timestamp in descending order
            fetchedConversations.sort((a, b) => {
                const dateA = new Date(a.timestamp)
                const dateB = new Date(b.timestamp)
                return dateB.getTime() - dateA.getTime() // Descending order
            })
    
            setConversations(fetchedConversations) 
        } catch (e: any) {
            setFetchError(`${e}`) 
            setIsLoading(false) // Stop loading on error
        }
    }, [userAuth, chatbotId]) 

    // Load conversations on component mount
    useEffect(() => {
        loadConversations()
    }, [loadConversations])

    useEffect(() => {
        if (conversations.length > 0) {
            setSelectedConversation(conversations[0])
            setSelectedConversationId(conversations[0].id)
        }
    }, [conversations])

    const handleConversationClick = (conversation: Conversation) => {
        setSelectedConversation(conversation)
        setSelectedConversationId(conversation.id) 
    }

    if (isLoading) {
        return (
            <div className="w-full flex flex-col items-center">
                <Grid
                    height="30"
                    width="30"
                    color="#7398E8"
                    ariaLabel="loading"
                    radius="12"
                    wrapperClass="mt-24"
                    visible
                />
                <div className="text-gray-600 mt-3">Loading conversations...</div>
            </div>
        )
    }


    if (fetchError) {
        return (
          <div className="w-full h-screen flex flex-col justify-center items-center text-center bg-white p-8 rounded-lg">
            <h2 className="text-md font-semibold mb-2">Something went wrong.</h2>
            <h3 className="text-xs text-slate-600 mb-1">Something went wrong with fetching the conversations for this chatbot.</h3>
            <p className="text-xs text-red-600 mb-1">{fetchError}</p>
          </div>
        )
      }

      return (
        <div>
            {/* Title */}
            <div className="w-full mb-3">
                <h2 className="text-lg font-semibold">Chat Logs</h2>
                {isLoading && <div>Finding conversations...</div>}
            </div>
    
            {!isLoading && (
                <div>
                    {/* Main content */}
                    <div className="flex flex-col md:flex-row">
                        {/* Conversation tiles column */}
                        <div className="flex h-[550px] max-w-sm self-start rounded-md border border-slate-300 mb-2 md:mb-0 md:mr-2">
                            <div className="overflow-y-auto">
                                {conversations.length > 0 ? (
                                    conversations.map(conversation => {
                                        const firstMessage = conversation.messages[0]
                                        const firstMessageContent = firstMessage ? firstMessage.content : 'No messages'
                                        const firstMessageDate = firstMessage && firstMessage.timestamp
                                                                ? new Date(firstMessage.timestamp).toLocaleDateString()
                                                                : ''
    
                                        return (
                                            <div
                                                key={conversation.id}
                                                onClick={() => handleConversationClick(conversation)}
                                                className={`p-4 cursor-pointer ${
                                                    conversation.id === selectedConversationId ? 'bg-slate-100' : 'hover:bg-gray-100'
                                                } border-b border-gray-300`}
                                            >
                                                <div className="font-semibold">{"User: " + firstMessageContent.substring(0, 20)}</div>
                                                <div className="text-xs text-gray-500">{firstMessageDate}</div>
                                            </div>
                                        )
                                                                
                                    })
                                ) : (
                                    <div className="p-4 text-gray-500">No conversations to display</div>
                                )}
                            </div>
                        </div>
    
                        {/* Chatbot display column */}
                        <div className="flex h-[550px] flex-1 flex-col rounded-md border border-slate-300 justify-between md:ml-4">
                            <div className="overflow-y-auto">
                                {selectedConversation ? (
                                    <ChatbotChatbox
                                        key={selectedConversation.id}
                                        introMessages={selectedConversation.messages}
                                        chatbotId={chatbotId}
                                        displayName={chatbotName}
                                        disableInputInteraction
                                    />
                                ) : (
                                    <div>Select a conversation to view messages</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )    
}

export default MessageDashboard