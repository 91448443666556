export default function FAQAccordion() {
  return (
    <>
      <p className="text-2xl text-center font-medium tracking-tight my-4" style={{marginTop: '80px'}}>FAQ</p>
      <div className="w-full md:max-w-[750px] lg:max-w-[950px] px-4 mb-8 self-center">

        <div className="grid divide-y divide-neutral-200">
          <div className="py-5">
            <details className="group">
              <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                <span> What is ChatQ?</span>
                <span className="transition group-open:rotate-180">
                  <svg fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                  </svg>
                </span>
              </summary>
              <p className="text-slate-500 mt-3 group-open:animate-fadeIn">
                ChatQ AI allows you to easily build chatbots customized for your Shopify store.<br /><br />
                Our chatbots can answer common customer questions about order status, return process, and product recommendations, in effect becoming a sales agent and customer experience specialist in a box that can drive more sales and boost customer retention for your store.
                  </p>

            </details>
          </div>
          <div className="py-5">
            <details className="group">
              <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                <span> How can I add a chatbot to my website?</span>
                <span className="transition group-open:rotate-180">
                  <svg fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                  </svg>
                </span>
              </summary>
              <p className="text-slate-500 mt-3 group-open:animate-fadeIn">
                ChatQ provides an embed link to add to your website HTML in order to display a chat bubble, which your users can use to chat with your custom chatbot when they visit your website.
              </p>
            </details>
          </div>
          <div className="py-5">
            <details className="group">
              <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                <span> What data does ChatQ take in?</span>
                <span className="transition group-open:rotate-180">
                  <svg fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                  </svg>
                </span>
              </summary>
              <p className="text-slate-500 mt-3 group-open:animate-fadeIn">
                ChatQ can automatically scrape your Shopify website, or train on any documents you provide, to provide high-quality answers about your store to your customers.<br /><br /> 
                ChatQ also connects to your Shopify store to automatically retrieve information about order status, refund status or available products to answer common customer questions. <br /><br /> 
                Simply provide your Shopify store URL in our chatbot builder and ChatQ will provide you with a deployable chatbot in less than a minute!
              </p>
            </details>
          </div>
        </div>
      </div>
    </>
  )
}
