import { CheckCircleIcon } from "@heroicons/react/24/outline"
import { loadStripe } from '@stripe/stripe-js'
import NavigationFooter from "../Navigation/NavigationFooter"
import NavigationHeader from "../Navigation/NavigationHeader"
import { useUserValue } from "../Auth/UserContext"

const sharedProjectFeatures = [
  'GPT-4 supported',
  'Embed on Shopify',
]

const tiers = [
  // {
  //   title: 'Basic',
  //   id: 'price_1MyoSxJwov5zkZe461UdFGVe',
  //   costPerMonth: 3.99,
  //   features: [
  //     '5 Chatbots',
  //     '1,000 messages',
  //     ...sharedProjectFeatures
  //   ]
  // },
  {
    title: 'Standard',
    id: 'price_1OLX6mJwov5zkZe4MOQ4c18Q',
    costPerMonth: 29.99,
    features: [
      '1 Chatbot',
      '5,000 messages',
      ...sharedProjectFeatures
    ]
  },
  {
    title: 'Pro',
    id: 'price_1MyoUUJwov5zkZe4odvw1CRX',
    costPerMonth: 49.99,
    features: [
      '2 Chatbots',
      '10,000 messages',
      ...sharedProjectFeatures
    ]
  },
  {
    title: 'Enterprise',
    id: 'price_1OLX5qJwov5zkZe4tFhZlBK2',
    costPerMonth: 129.99,
    features: [
      '5 Chatbots',
      '25,000 messages',
      ...sharedProjectFeatures
    ]
  }
]
const STRIPE_PUBLISHABLE_KEY = 'pk_live_51Myo1dJwov5zkZe4oM1j2zyKV0IYGqXQ9hAqlNeOXVoLqdojojGUps7dCjhS35u92QXzyIdZtppEoYhIHFtWvu9R00i2k4J2dV'

const Pricing = () => {
  const { userAuth } = useUserValue()
  const userId = userAuth?.uid

  const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY)

  const onChoosePricingTier = async (priceId: string) => {
    // Load the Stripe instance
    const stripe = await stripePromise
  
    // Redirect to the Stripe checkout page
    const result = await stripe?.redirectToCheckout({
      lineItems: [{ price: priceId, quantity: 1 }],
      clientReferenceId: userId,
      mode: 'subscription',
      successUrl: 'https://chatq.co',
      cancelUrl: 'https://chatq.co/pricing',
    })
  
    if (result?.error) {
      console.error('Error redirecting to checkout:', result.error.message)
    }
  }
  

  return (
    <div>
      <div className="min-h-screen flex flex-col px-8">
        <NavigationHeader />
        <div className="flex flex-col flex-1 items-center justify-evenly">
          <div className="flex flex-col w-full">

            <h1 className="self-center font-bold text-5xl mb-10 tracking-tight">Pricing</h1>
            <div className="flex w-4xl self-center justify-around items-center flex-wrap">
              {tiers.map(tier => (
                <div className="w-56 p-8 mx-3 mb-6 shadow-md border border-slate-200 rounded-2xl" key={tier.id}>
                  <p className="text-sm text-slate font-medium pb-4">{tier.title}</p>
                  <p className="text-sm text-slate font-medium pb-4"><span className="text-5xl font-semibold text-black tracking-tighter">${tier.costPerMonth}</span> /month</p>
                  {tier.features.map(feature => (
                    <div className="flex items-center py-1" key={feature}>
                      <CheckCircleIcon className="h-5 w-5 text-green-600 mr-2" />
                      <p className="text-black tracking-tight text-sm">{feature}</p>
                    </div>
                  ))}
                  <button
                      className="mt-6 w-full px-3 py-1.5 h-fit rounded-md shadow bg-slate-200 hover:bg-black hover:text-white text-sm font-medium tracking-tight"
                      onClick={() => onChoosePricingTier(tier.id)}
                    >
                      Buy now
                    </button>
                </div>
              ))}
            </div>
          </div>
          {/* empty div to evenly justify content above against */}
          <div />
        </div>

      </div>
      <NavigationFooter />
    </div>
  )
}

export default Pricing