import './index.css'
import { useState, useEffect } from 'react'
import { User, onAuthStateChanged } from 'firebase/auth'
import { Routes, Route } from "react-router-dom"
import { Grid } from 'react-loader-spinner'
import { UserProvider, UserSettings } from './components/Auth/UserContext'
import { auth } from './components/Auth/firebase'
import Home from "./components/Home/Home"
import HowItWorks from "./components/Home/HowItWorks"
import Login from "./components/Login/Login"
import MyChatbots from "./components/MyChatbots/MyChatbots"
import Account from "./components/Account/Account"
import Pricing from "./components/Pricing/Pricing"
// import FeaturedChatbots from "./components/FeaturedChatbots/FeaturedChatbots"
// import FeaturedChatbot from "./components/FeaturedChatbots/FeaturedChatbot"
import CreateNewChatbot from './components/CreateNewChatbot/CreateNewChatbot'
import { Chatbot } from './components/Chatbot/Chatbot'
import NotFound from './components/NotFound/NotFound'
import ChatbotIframe from './components/Chatbot/ChatbotIframe'
import TermsOfService from './components/Home/TermsOfService'
import PrivacyPolicy from './components/Home/PrivacyPolicy'
import { MyChatbotsProvider } from './components/MyChatbots/MyChatbotsContext'
import { Chatbot as ChatbotType } from './components/MyChatbots/MyChatbots'
// import {EmailsProvider} from './components/Chatbot/EmailsProvider'

// export const process.env.NODE_ENV = 'development'
export const BASE_FETCH_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : ''
// export const BASE_FETCH_URL = 'http://localhost:8000'
export const fetchUserChatbots = (token?: string) => fetch(`${BASE_FETCH_URL}/api/v1/get-chatbots`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
  },
})

function App() {
  const [userAuth, setUserAuth] = useState<User | null | undefined>(undefined)
  const [userSettings, setUserSettings] = useState<UserSettings | null>(null)
  const [myChatbotsContext, setMyChatbotsContext] = useState<ChatbotType[]>([])

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        try {
          const token = await authUser.getIdToken()
          const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          }
  
          const fetchAccountSettings = async () => {
            const res = await fetch(`${BASE_FETCH_URL}/api/v1/get-account-settings`, requestOptions)
            if (!res.ok) {
              throw new Error(res.status + ": " + res.statusText)
            }
            const jsonRes = await res.json()
            const settings = {
              remainingMessages: jsonRes.remaining_messages,
              pricingId: jsonRes.pricing_id,
              pricingTier: jsonRes.pricing_tier,
              maxChatbots: jsonRes.max_chatbots,
            }
            setUserSettings(settings)
            setUserAuth(authUser)
          }
  
          const fetchChatbots = async () => {
            // console.log(`token333: ${token}`)
            const res = await fetchUserChatbots(token)
            if (!res.ok) {
              throw new Error(res.status + ": " + res.statusText)
            }
            const jsonRes = await res.json()
            setMyChatbotsContext(jsonRes.chatbots)
          }
  
          await Promise.all([fetchAccountSettings(), fetchChatbots()])
  
        } catch (e) {
          console.error(e)
        }
      } else {
        // not logged in
        setUserAuth(null)
      }
    })
    return () => {
      unsubscribe()
    }
  }, [])
  

  // onAuthStateChanged is still in progress
  if (userAuth === undefined) {
    return (
      <div className="h-screen w-full flex items-center justify-center">
        <Grid
          height="80"
          width="80"
          color="#7398E8"
          ariaLabel="loading"
          radius="12"
          visible={true}
        />
      </div>
    )
  }

  const userValues = {
    userAuth,
    userSettings
  }

  return (
    <UserProvider value={userValues}>
      <MyChatbotsProvider value={[myChatbotsContext, setMyChatbotsContext]}>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/featured-chatbots" element={<FeaturedChatbots />} />
          <Route path="/featured-chatbots/:chatbotId" element={<FeaturedChatbot />} /> */}
          <Route path="/my-chatbots" element={<MyChatbots />} />
          <Route path="/new-chatbot" element={<CreateNewChatbot />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/chatbot/:chatbotId" element={
              // <EmailsProvider>
                <Chatbot />
              // </EmailsProvider>
            } />
          <Route path="/account" element={<Account />} />
          <Route path="pricing" element={<Pricing />} />
          <Route path="/login" element={<Login />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<NotFound />} />
          {/* TODO figure out permissions of enabling access to iframe based on private/public chatbot settings */}
          <Route path="/chatbot-iframe/:chatbotId?" element={<ChatbotIframe />} />
        </Routes>
      </MyChatbotsProvider>
    </UserProvider>
  )
}

export default App

