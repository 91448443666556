import React, { useEffect, useState, useCallback} from 'react'
import { BASE_FETCH_URL } from "../../App"

interface ChatbotAnalyticsProps {
  // totalConversations: number 
  // avgMsgsPerConversation: number 
  userAuth: any 
  chatbotId: string 
}

const ChatbotAnalytics: React.FC<ChatbotAnalyticsProps> = ({
  // totalConversations,
  // avgMsgsPerConversation,
  userAuth,
  chatbotId,
}) => {
  const [emails, setEmails] = useState<string[]>([]) 
  const [error, setError] = useState<string | null>(null) 
  const [isLoading, setIsLoading] = useState(true)  // Added loading state

  const getChatbotEmails = useCallback(async () => {
    try {
      setIsLoading(true)
      const token = await userAuth?.getIdToken()
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          chatbotId: chatbotId,
        }),
      }
      const response = await fetch(`${BASE_FETCH_URL}/api/v1/get-chatbot-emails`, requestOptions) 
      const data = await response.json() 

      if (!response.ok) {
        throw new Error(data.message || response.status + ": " + response.statusText) 
      }

      setEmails(data.emails || []) 
    } catch (e: any) {
      setError(`Error getting chatbot emails: ${e.message || e}`) 
      console.log(`Error getting chatbot emails: ${e.message || e}`) 
    } finally {
      setIsLoading(false)  // Set loading to false when fetch is completed or if an error occurs
    }
  }, [userAuth, chatbotId]) // include all variables that the function depends on


  useEffect(() => {
    getChatbotEmails()
  }, [getChatbotEmails]) // include getChatbotEmails in the dependency array

  return (
    <div className="flex flex-col flex-1 justify-start w-full max-w-3xl self-center">
      <div>
      <div>
        <h2 className="py-4 text-md tracking-tight">Customer Leads</h2>
        <div className="shadow-md rounded-lg p-6">
          {isLoading ? (
            <p className="text-sm text-slate-600">Checking for new leads...</p>
          ) : emails.length > 0 ? (
            <ul>
              {emails.map((email, index) => (
                <li key={index} className="mb-2 text-sm">
                  {email}
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-sm text-slate-600">No emails found.</p>
          )}
        </div>
      </div>
        {/* <h2 className="py-4 text-md tracking-tight">Last 7 days</h2> */}
        {/* <div className="flex flex-col md:flex-row justify-center w-full">
          <div className="shadow-md rounded-lg p-6 md:mr-3 flex-1 mb-3">
            <p className="text-xs text-slate-600 font-medium mb-px">Total Conversations</p>
            <p className="text-2xl font-medium">{totalConversations}</p>
          </div>
          <div className="shadow-md rounded-lg p-6 md:ml-3 flex-1 mb-3">
            <p className="text-xs text-slate-600 font-medium mb-px">Avg Messages Per Conversation</p>
            <p className="text-2xl font-medium">{avgMsgsPerConversation}</p>
          </div>
        </div> */}
      </div>
      <div>
      </div>
      {error && <div className="text-red-500 mt-3">{error}</div>}
      <div />
    </div>
  )
}

export default ChatbotAnalytics