import { User } from "firebase/auth"

type ChatbotEmbedProps = {
  userAuth: User | null
  chatbotId: string
}

const ChatbotEmbed = ({userAuth, chatbotId }: ChatbotEmbedProps) => {
  const iframeUrl = `https://custom-gpt-ceb44.firebaseapp.com/chatbot-iframe/${chatbotId}`
  // from https://github.com/philippe-heitzmann/chatter-chatbot-message-bubble
  const chatBubbleUrl = 'https://cdn.jsdelivr.net/gh/philippe-heitzmann/chatter-chatbot-message-bubble@v0.0.5/index.min.js'
  const userId = userAuth?.uid
  const iframeSrc = `${iframeUrl}?userId=${userId}`
  return (
    <div className="max-w-3xl w-full self-center">
      <h2 className="pt-4 pb-2 text-md tracking-tight">Embed on Website</h2>
      {/* <p className="text-xs text-accent-blue pb-3 font-medium">NOTE: In order for embedding to work, chatbot visibility must be set to either <span className="italic">public</span> or <span className="italic">private but can be embedded on website</span>. You can adjust this on the Settings tab.</p> */}
      <p className="text-xs text-slate-500 pb-3">To add this chatbot on your website, add this iframe code to your HTML code:</p>

      <div className="bg-slate-100 rounded-sm p-2 mb-3 font-mono text-xs overflow-x-scroll">
        <p>{"<iframe"}</p>
        <p>{`src="${iframeSrc}"`}</p>
        <p>{`width="100%"`}</p>
        <p>{`height="700"`}</p>
        <p>{"></iframe>"}</p>
      </div>
      <p className="text-xs text-slate-500 pb-3">To add a chat bubble to the bottom right corner of your website that opens this chatbot, add this script tag to your HTML code:</p>
      <div className="bg-slate-100 rounded-sm p-2 mb-3 font-mono text-xs overflow-x-scroll">
        <p>{"<script"}</p>
        <p>{`src="${chatBubbleUrl}"`}</p>
        <p>{`id="${chatbotId}"`}</p>
        <p>{`idp=${userId}`}</p>
        <p>{"></script>"}</p>
      </div>
    </div>
  )
}

export default ChatbotEmbed