import { useState } from "react"

import { Oval } from "react-loader-spinner"
import { logEvent } from "firebase/analytics"
import { analytics } from '../Auth/firebase'
import { BASE_FETCH_URL } from "../../App"
import { useUserValue } from "../Auth/UserContext"
import { Chatbot } from "../MyChatbots/MyChatbots"
import { useMyChatbotsValue } from "../MyChatbots/MyChatbotsContext"
import { DataImportProps } from "./CreateNewChatbot"


const RawTextImport = ({ goBack, navigateToChatbot }: DataImportProps) => {
  const { userAuth } = useUserValue()
  const [myChatbots, setMyChatbots] = useMyChatbotsValue()
  const [chatbotName, setChatbotName] = useState('')
  const [rawText, setRawText] = useState<string>('')
  const [submitInProgress, setSubmitInProgress] = useState(false)
  const [error, setError] = useState('')

  const handleSubmit = async () => {
    setSubmitInProgress(true)
    const token = await userAuth?.getIdToken()
    try {
      const requestBody = {
        chatbotName: chatbotName,
        document: {
          documents: [
            {
              text: rawText,
            },
          ],
        },
      }

      const res = await fetch(`${BASE_FETCH_URL}/api/v1/create-chatbot-raw-text`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      })
      if (res.ok) {
        const jsonResponse = await res.json()
        const newChatbot: Chatbot = {
          name: jsonResponse.chatbotName,
          id: jsonResponse.chatbotId,
          image: jsonResponse.chatbotImage
        }
        setMyChatbots([...myChatbots, newChatbot])
        logEvent(analytics, 'success_create_chatbot_rawtext')
        navigateToChatbot(newChatbot.id)
      } else {
        setSubmitInProgress(false)
        if (res.status === 401) {
          throw new Error(`${res.status} ${res.statusText}: Please log in first.`)
        }
        logEvent(analytics, 'failure_create_chatbot_rawtext')
        throw new Error(res.status + ": " + res.statusText)
      }
    } catch (e: any) {
      setError(`${e}`)
      setSubmitInProgress(false)
    }
  }


  return (
    <div className="flex flex-col max-w-4xl w-full self-center justify-center">
      <button onClick={goBack} className="h-min self-start text-slate-500 bg-slate-100 rounded-lg mb-6"><p className="py-1.5 px-2 text-xs">&larr; back</p></button>
      <input className="text-md border border-slate-200 rounded-lg p-2 mb-2" type="text" placeholder="Chatbot name" value={chatbotName} onChange={(e) => { setChatbotName(e.target.value) }} />
      <textarea className="h-80 text-md border border-slate-200 rounded-lg p-2 mb-2" value={rawText} placeholder="Type or paste in text data here" onChange={(e) => { setRawText(e.target.value) }} />
      {error && <p className="text-xs text-red-600 pb-2">{error}</p>}
      <button onClick={handleSubmit} disabled={!(rawText && chatbotName) || submitInProgress} className={`${!(rawText && chatbotName) ? "opacity-40" : ""} bg-black self-center text-white mt-8 mb-2 shadow-lg py-2 w-full max-w-sm rounded-lg text-md tracking-tight flex justify-center`}>{submitInProgress ? <Oval
        height={20}
        width={20}
        color="white"
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor="white"
        strokeWidth={4}
        strokeWidthSecondary={4}
      /> : 'Create Chatbot'}</button>
    </div >
  )
}

export default RawTextImport