// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getAuth } from 'firebase/auth'
import { getStorage } from "firebase/storage"
import { getAnalytics } from "firebase/analytics" 


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCp2ov4VLpMYBpnB8d6DAm2ywNw5veZXOc",
  authDomain: "custom-gpt-ceb44.firebaseapp.com",
  projectId: "custom-gpt-ceb44",
  storageBucket: "custom-gpt-ceb44.appspot.com",
  messagingSenderId: "326908631506",
  appId: "1:326908631506:web:ceab34d6e8565fb091500b",
  measurementId: "G-JRW67HCG9X"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
// eslint-disable-next-line no-unused-vars
export const analytics = getAnalytics(app)

export const auth = getAuth(app)
export const storage = getStorage(app)

// disabling this for now since it causes more bugs as we're connecting and debugging FE <-> BE
// if (process.env.NODE_ENV === 'development') {
//   // ensure this port # mirrors the auth emulator port specified in firebase.json
//   connectAuthEmulator(auth, "http://localhost:9099")
// }


