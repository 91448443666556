import React, { ReactElement, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DocumentTextIcon, FolderArrowDownIcon, GlobeAltIcon } from '@heroicons/react/24/outline'
import NavigationFooter from '../Navigation/NavigationFooter'
import NavigationHeader from '../Navigation/NavigationHeader'
import RawTextImport from './RawTextImport'
import FileImport from './FileImport'
import WebsiteImport from './WebsiteImport'


const DATA_SOURCES: { type: SelectedDataSource, title: string, icon: ReactElement }[] = [
  { type: 'rawText', title: "Raw Text", icon: <DocumentTextIcon className="h-6 w-6" /> },
  { type: 'file', title: "File", icon: <FolderArrowDownIcon className="h-6 w-6" /> },
  { type: 'website', title: "Website", icon: <GlobeAltIcon className="h-6 w-6" /> }
]

type SelectedDataSource = 'rawText' | 'file' | 'website' | null

type SetSelectedDataSourceProps = {
  setSelectedDataSource: React.Dispatch<React.SetStateAction<SelectedDataSource>>
}


const SelectDataSource = ({ setSelectedDataSource }: SetSelectedDataSourceProps) => {
  return (
    <>
      <div className="flex flex-col items-center">
        {DATA_SOURCES.map(source => (
          <button key={source.type} onClick={() => setSelectedDataSource(source.type)} className="flex justify-center items-center bg-white text-black border border-slate-100 text-md tracking-tight mb-12 p-2 h-fit w-full max-w-xs rounded-lg shadow-md">
            <p className="p-2">{source.icon}</p>
            <p className="p-2">{source.title}</p>
            <p className="invisible w-8 p-2" />
          </button>
        ))}
      </div>
    </>
  )
}

export type DataImportProps = {
  goBack: () => void
  navigateToChatbot: (chatbotId: string) => void
}


const CreateNewChatbot = () => {
  const navigate = useNavigate()
  const [selectedDataSource, setSelectedDataSource] = useState<SelectedDataSource>(null)
  const goBack = () => {
    setSelectedDataSource(null)
  }
  const navigateToChatbot = (chatbotId: string) => navigate(`/chatbot/${chatbotId}`)

  return (
    <>
      <div className="min-h-screen flex flex-col px-8">
        <NavigationHeader />
        <div className="flex flex-col flex-1 items-center justify-evenly">
          <>
            {!selectedDataSource ?
              <div className="">
                <h1 className="text-5xl font-bold tracking-tight my-12 text-center">Create New Chatbot</h1>
                <SelectDataSource setSelectedDataSource={setSelectedDataSource} />
              </div> :
              selectedDataSource === 'rawText' ?
                <RawTextImport
                  navigateToChatbot={navigateToChatbot}
                  goBack={goBack} />
                : selectedDataSource === 'file' ?
                  <FileImport
                    navigateToChatbot={navigateToChatbot}
                    goBack={goBack} />
                  : selectedDataSource === 'website' ?
                    <WebsiteImport
                      navigateToChatbot={navigateToChatbot}
                      goBack={goBack} /> : null
            }
          </>
          {/* empty div to justify above content evenly */}
          <div />
        </div>
      </div>
      <NavigationFooter />
    </>

  )
}

export default CreateNewChatbot