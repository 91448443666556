import NavigationFooter from "../Navigation/NavigationFooter"
import NavigationHeader from "../Navigation/NavigationHeader"

const TermsOfService = () => {
  return (
    <div>
      <div className="min-h-screen flex flex-col px-8">
        <NavigationHeader />
        <div className="flex flex-col flex-1 items-center justify-evenly">
          <div className="flex flex-col w-full max-w-5xl mb-40">
            <h1 className="text-5xl font-bold tracking-tight my-8 self-center pb-4">Terms of Service</h1>
            {/* <a className="text-lg font-medium underline text-accent-blue text-center" target="_blank" href="https://docs.google.com/document/d/1XsJentSnbROceXS--c5OXuuao3LoBMakzgNcuck1lVU/edit?usp=sharing" rel="noreferrer">View Terms of Service</a> */}
            {/* <h1 className="text-5xl font-bold tracking-tight my-8 self-center">  </h1> */}
            <div className="pb-4">
            <p>Welcome to ChatQ! We’re so glad you’re here. Please read the following terms before using our Services; you will be agreeing to, and will be bound by them through the continued use of our Services.</p>
            </div>
            <h2 className="text-lg font-medium tracking-tighter pb-4">Agreement</h2>
              <p style={{ marginBottom: '1rem' }}> These Terms of Use along with our Privacy Policy and Data Processing Addendum (the “DPA”) incorporated herein, constitute the agreement (the “Agreement”) established by and between ChatQ.co (referred to as ”ChatQ”, ”we” or “us” or ”the Company”) and any person or entity registering to or using the Services supplied by us (referred to as “Client” and “you”) collectively “parties”, individually “party”. </p>

              <p style={{ marginBottom: '1rem' }}>Accepting this Agreement is a condition of provisioning our  Services to you. BY COMPLETING THE REGISTRATION PROCESS, ACCESSING OR USING THE SERVICES YOU (I) ACKNOWLEDGE AND AGREE THAT YOU HAVE READ, UNDERSTOOD AND ACCEPTED THIS AGREEMENT, (II) HEREBY REPRESENT AND WARRANT THAT YOU ARE AUTHORIZED TO ENTER OR ACT ON BEHALF OF THE OWNER OF THE ACCOUNT, AND BIND TO THIS AGREEMENT AND (III) YOUR USE OF THE SERVICES IS FOR COMMERCIAL/BUSINESS PURPOSES ONLY. If you do not have the legal authority to enter this Agreement, do not understand this Agreement, or do not agree to the Agreement, please do not register to, or use the Services.</p>

              <p style={{ marginBottom: '1rem' }}> You and we agree and are bound by the following provisions:
                          This Agreement is effective between you and us on the day of your registration, access to or use of the Services (as further defined below) or by executing an applicable Order Form (the “Effective Date”).</p>

              <h2 className="text-lg font-medium tracking-tighter pb-4">Definitions</h2>

              <p>The following terms shall have the meaning specified below:</p>
              <ul style={{ listStyleType: 'disc', listStylePosition: 'outside', paddingLeft: '2rem', marginTop: '1rem', marginBottom: '1rem' }}>
                <li style={{ marginBottom: '0.75rem' }}>“Account”/“License” means the account created by the Client to Services.</li>
                <li style={{ marginBottom: '0.75rem' }}>“Agent” means a named user(s) in the Services who are permitted by the Client to use and operate the Services for or on behalf of the Client.</li>
                <li style={{ marginBottom: '0.75rem' }}> “Confidential Information” means all nonpublic information, including, but not limited to, trade secrets, computer programs, technical drawings, algorithms, formulas, processes, ideas, inventions (patentable or not), other technical or business information, physical samples, financial, business, sales information, know-how, terms of agreements, negotiations or proposals, all data, and such other information disclosed by ChatQ in whatever form and which (i) is known by the receiving party (Client, Agent, or any other Client’s authorized representative) to be confidential; (ii) under circumstances by which the receiving party should reasonably understand such information is to be treated as confidential, whether or not marked by the disclosing party as “Confidential” or otherwise is marked as or stated to be confidential. Confidential Information may not be used, published, or redistributed by the receiving party without the prior written consent of ChatQ.</li>
                <li style={{ marginBottom: '0.75rem' }}> “Client Data” means data, information (including but not limited to Personal Data) and any material, content, phrases, entries uploaded to or created in the Services or transmitted through or stored in the Services (collectively “Input”) by the Client, its Agents or any End-User, or otherwise made available, by or for Client to or through the Services.</li>
                <li style={{ marginBottom: '0.75rem' }}> “End-Users” mean customers of the Client.</li>
                <li style={{ marginBottom: '0.75rem' }}>“Order Form” means any applicable ordering document between the parties that specifies mutually agreed upon rates for certain Services and other commercial terms. Each Order Form executed by the parties is governed by this Agreement and is subject thereto.</li>
                <li style={{ marginBottom: '0.75rem' }}>“Services” any services or applications provided by ChatQ to you (a) on a trial, (b) free of charge basis or (c) as Paid Services.</li>
                <li style={{ marginBottom: '0.75rem' }}>“Personal Data” means any information relating to an identified or identifiable natural person under the Client’s Account, who can be identified, directly or indirectly.</li>
                <li style={{ marginBottom: '0.75rem' }}>“Sites” means any domain or application operated by ChatQ from which the Services are available to Clients.</li>
                <li style={{ marginBottom: '0.75rem' }}>“Sub-Processor” means any person or entity (including any third party who renders services to the Company), authorized as another processor under the Agreement to have logical access to and process Personal Data in order to provide parts of the Services.</li>
              </ul>
              <h2 className="text-3xl font-bold tracking-tight my-8 self-center pb-4">1. General</h2>
                <p style={{ marginBottom: '1rem' }}>1.1. Client’s access to the Internet is not the subject of the Agreement and ChatQ does not guarantee the compatibility of offered Services with other providers’ software. The Client bears sole responsibility for the functionality of its Internet network, including the transmission paths and its own hardware and for the choice and consequences of using other software, including its applicability to the Client’s objectives.</p>
                <p style={{ marginBottom: '1rem' }}>1.2. Usage of the Services is subject to the then-current version of the Agreement posted on Sites and we advise you to periodically review the latest currently effective Agreement. We reserve the right to change any part or all of the Agreement from time to time at our sole discretion without prior notice to you. The updated version supersedes all prior versions, as well as is effective and binding immediately upon posting the revised version on Sites applicable to your Services. However, modifications affecting payment shall take place with prior explicit notice to you at least thirty (30) days prior to the change implementation and, if not clearly rejected within 7 (seven) days of the notification, are deemed accepted and binding. Your continued use of the Services on or after the date of the updated version is effective and constitutes your acceptance of such updated terms. If you do not agree with the changes to the Agreement, you can delete your Account at any time.</p>
              <h2 className="text-3xl font-bold tracking-tight my-8 self-center pb-4">2. Access and the use of the Services</h2>
                <p style={{ marginBottom: '1rem' }}>2.1. When creating an Account and during the term of the Agreement, you must provide and keep the Account information true, accurate, current, and complete about yourself as requested to create and maintain the Account.</p>
                <p style={{ marginBottom: '1rem' }}>2.2. If you are the party that accepted the Agreement and you re-assign your Account to an authorized entity or person for administration purposes such Account re-assignment will not excuse your obligations under the Agreement and your use of the Services will continue to be subject to them.</p>
                <p style={{ marginBottom: '1rem' }}>2.3. Our Services can be accessed solely by logging in to the Services on a particular Site. Every Client is assigned credentials for the identification and authentication of Agents in the Services (except Services in which Agents are not required). Access to, and use of, the Services is restricted to the specified number of individual Agents permitted under the Client’s Account to the subscribed Services. The Client further agrees and acknowledges that the individual Agents’ login data for the Services shall only be used by one (1) designated individual Agent and may not (i) be passed on or used by others or several Agents at the same time, or (ii) shared or used by more than one Agent, but that Agent’s credentials may be reassigned to new individuals replacing former individuals who no longer require ongoing use of the Services. New and/or additional Agents must be notified in writing (including electronically) by you to ChatQ in advance so that individual login data can be provided to each Agent and, if necessary, the subscription fee re-calculated. You are solely responsible for the proper protection and storage of assigned password and log-in (credentials). The Client and its Agents are responsible for maintaining the confidentiality of all of the Agent credential information for the Client’s Account.</p>
                <p style={{ marginBottom: '1rem' }}>2.4. The Client must be at least sixteen (16) years of age to be able to register and access an Account. ChatQ does not knowingly provide Services to any person under the age of sixteen (16). In the event that it comes to our knowledge that a person under the abovementioned age is accessing or using the Services, with no liability whatsoever towards such person, we will prohibit and block such Account without any prior notice and we will make all efforts to promptly delete any data with regard to such Account.</p>
                <p style={{ marginBottom: '1rem' }}>2.5. You are responsible for compliance with the provisions of the Agreement by you, Agents and End-Users and for any and all activities that occur under your Account. Without limiting the foregoing, you are solely responsible for ensuring that the use of the Services to store and transmit Client Data is compliant with all applicable laws and regulations, as well as any and all privacy policies, agreements or other obligations you may maintain or enter into with Agents or End-Users.</p>
                <p style={{ marginBottom: '1rem' }}>2.6. You are also solely responsible for obtaining the authorizations, licenses and consents, if and as required by any applicable law, to make the Services available to End-Users. When you provide any Client Data to ChatQ, you (whether you are the Client or the administrator operating on behalf of the Client) represent and warrant that you have full authority to provide us with such Client Data and its submission and use, as you authorize herein, will not violate (i) any applicable law, (ii) any third-party intellectual property, privacy, publicity, or other rights, or (iii) any of your or third-party policies or terms governing your Client Data.</p>
                <p style={{ marginBottom: '1rem' }}>2.7. The Client understands and has become familiar with the technical requirements necessary to access and use the Services and has no objections in respect thereof. The Client is aware of risks and threats connected with electronic data transmission.</p>
              <h2 className="text-3xl font-bold tracking-tight my-8 self-center pb-4">3. Acceptable Use Policy</h2>
                <p style={{ marginBottom: '1rem' }}>3.1. You are solely and strictly liable for Client Data any activity that occurs under your Account and you also agree not to access, or attempt to access, other Client’s Accounts and/or any data of other Clients. Furthermore, you are solely responsible for your Client Data and the consequences of posting or publishing them, through the Services, in any way. Additionally, you understand and agree that ChatQ shall have no responsibility or liability whatsoever for Client Data and other materials and copyrightable materials such as literary works, text, images, photos, videos, and any other materials, which as part of Client Data may be submitted, provided, generated, created in whatsoever form by the Client, its Agents and/or its End-Users to or in the Services.</p>
                <p style={{ marginBottom: '1rem' }}>3.2. You exclusively own and reserve all rights, titles, and interest to your Client Data, subject to our worldwide, non-exclusive and royalty-free right to use, process, disclose and transfer only as necessary to provide the Services to you and/or otherwise permitted by the Agreement.</p>
                <p style={{ marginBottom: '1rem' }}>3.3. ChatQ is not responsible for the way you use the Services and Client Data provided that ChatQ reserves the right to use them in the event of fraudulent or illegal activity of the Client without its prior consent.</p>
                <p style={{ marginBottom: '1rem' }}>3.4. You are solely responsible for the lawful and consistent with the Agreement usage of the Services. Violation of the Agreement, applicable laws or generally accepted standards and principles may lead to the immediate termination of the Agreement without prior notice to the Client. In particular and without limitation, the Client declares that it will not:(i) Use the Services to transmit or store any content or communication or engage in or encourage any activity that is illegal, harmful, deceptive, unsolicited, violating others’ rights or otherwise poses a threat to the public or infringes any copyright, patent, trademark, trade secret, or other intellectual property rights of others, or otherwise cause ChatQ to become unable or impaired in its ability to provide the Service, including:</p>
                <ul style={{ listStyleType: 'decimal', listStylePosition: 'outside', paddingLeft: '2rem', marginTop: '1rem', marginBottom: '1rem' }}>
                <li style={{ marginBottom: '0.75rem' }}>Interference with the Services or otherwise negatively impacting any aspect of the Services or any third-party networks that are linked to the Services or disrupts the integrity or performance of the Services and its components or endanger, interfere, compromise or circumvent the privacy, use, and/or security of the Services, including, without limitation, submitting data or content that may contain viruses or other harmful components;</li>
                <li style={{ marginBottom: '0.75rem' }}>Hinder the functioning of the Services, especially in the form of reverse engineering or hacking the Services, copying, translating, disassembling or decompiling the Services, or otherwise modify the Services in whole or in part, or create derivative works based thereon or attempting to gain unauthorized access to the Services (or any portion thereof), or related ChatQ’s systems, networks, or data;</li>
                <li style={{ marginBottom: '0.75rem' }}>Creating a false identity or any attempt to mislead others as to the identity of the sender or the origin of any data or communications, or mask the origin of any data, content, or other information you submit. For example, by “spoofing,” “phishing,” manipulating headers or other identifiers, impersonating anyone else, or accessing the Services via another Client’s Account without their permission;</li>
                <li style={{ marginBottom: '0.75rem' }}>Use the Services in a way that violates the rights of other individuals or laws, this includes violating applicable laws requiring consent be obtained prior to transmitting, recording, collecting, or monitoring data or communications. Use the Services for benchmarking or for any other purpose harmful to ChatQ’s business operations or reputation;</li>
                <li style={{ marginBottom: '0.75rem' }}>Allow or encourage anyone else to commit any of the actions listed above.</li>
              </ul>
                <p style={{ marginBottom: '1rem' }}>3.5. The Client is responsible for procuring and maintaining the network connections that connect your network to the Services, including, but not limited to, browser software that supports protocols used by ChatQ and to follow ChatQ’s procedures for accessing the Services. It is your responsibility to ensure each of your Account/License is compatible with the then-current Services. We are not responsible for notifying Client, Agents or End-Users of any upgrades, fixes, or enhancements to any such software, or for any compromise of data, including Client Data, transmitted across computer networks or telecommunications facilities (including, but not limited to, the Internet) which are not owned, operated, or controlled by ChatQ. We have no responsibility for the reliability or performance of any network connections as described herein.</p>
                <p style={{ marginBottom: '1rem' }}>3.6. The Client undertakes to use the Services exclusively for its legitimate business purposes, provided that your activities are lawful and do not violate these Terms of Use and do not directly oppose any commercial and legal rights of ChatQ. Prior written consent of ChatQ is required for any non-business use of Services. The Client shall prevent unauthorized access by any third parties to the Services and shall also oblige its Agents to comply with these obligations. The unauthorized lending, sale or granting of further licenses and sublicenses to the Services or otherwise making available the Services to any third party by the Client, without the express consent of ChatQ is prohibited. Such acts are not deemed to be the proper use of the Services and may cause suspension or termination of this Agreement without prior notice to the Client.</p>
                <p style={{ marginBottom: '1rem' }}>3.7. The Client must not modify or change the purpose and use of the Services during the term of the Agreement or an applicable Order Form, if any. Misleading others as to the existence of cooperation, association, relationship, or acting on behalf of ChatQ is prohibited.</p>
                <h2 className="text-3xl font-bold tracking-tight my-8 self-center pb-4">4. Third Party Services; Third-Party Components</h2>
                  <p style={{ marginBottom: '1rem' }}>4.1. You may link the Services to certain third-party websites and other third-party services, independent from the Services (collectively, “Third Party Services”). You hereby acknowledge that ChatQ has no control over such Third Party Services, and further acknowledge and agree that you are responsible for receiving End-Users consent or fulfilling obligations required by applicable law and ChatQ is not responsible for the privacy, security or integrity of any Third Party Services or the practices and policies of any Third Party Services or for the availability of Third Party Services, and does not have responsibility or liability for any goods, services, content, advertisements, products, or any materials available on and/or through such Third Party Services. Most Third Party Services provide legal documents, including terms of use and privacy policy, governing the use of each such Third Party Services, their contents and services. We encourage you to read these legal documents carefully before using any such Third Party Services.</p>
                  <p style={{ marginBottom: '1rem' }}>4.2. You further acknowledge and agree that ChatQ shall not be responsible or liable, directly or indirectly, for any damage or loss whatsoever caused, or alleged to be caused, by or in connection with the use of or reliance on any goods, services, content, products or other materials available on or through any Third Party Services.</p>
                  <p style={{ marginBottom: '1rem' }}>4.3. The Services may use or include third party software, files and components that are subject to open source and third-party license terms (“Third Party Components”). Your right to use such Third Party Components as part of, or in connection with, the Services is subject to any applicable acknowledgements and license terms accompanying such Third Party Components, contained therein or related thereto. If there is a conflict between the licensing terms of such Third Party Components and these Terms of Use, the licensing terms of the Third Party Components shall prevail only in connection with the related Third Party Components. These Terms of Use do not apply to any Third Party Components accompanying or contained in the Services and ChatQ disclaims all liability related thereto. You acknowledge that ChatQ is not the author, owner or licensor of any Third Party Components, and that ChatQ makes no warranties or representations, express or implied, as to the quality, capabilities, operations, performance or suitability of Third Party Components. Under no circumstances shall the Services or any portion thereof (except for the Third Party Components contained therein) be deemed to be “open source” or “publicly available” software.</p>
                  <h2 className="text-3xl font-bold tracking-tight my-8 self-center pb-4">5. Ownership and Confidentiality</h2>
                  <p style={{ marginBottom: '1rem' }}>5.1. We exclusively own and retain all rights, titles, interest and intellectual property rights in and to the Services, the data, features and solutions provided thereby and the underlying software and technology (including Sites) and other materials made accessible or available by the ChatQ under to the Client’s Account, all as may be updated, improved, modified, enhanced or discontinued at any time and further to the brand names, logos and trademarks related to the foregoing (“Content”).</p>
                  <p style={{ marginBottom: '1rem' }}>5.2. We exclusively own and retain all rights to the Confidential Information disclosed to the receiving party. All Confidential Information of ChatQ and derivations thereof shall remain ChatQ’s sole and exclusive property, and no license or other right to such Confidential Information or ChatQ’s intellectual property is granted or implied hereby to the receiving party. The receiving party is obliged to hold all Confidential Information received from ChatQ in strict confidence and take all reasonable precautions to safeguard the Confidential Information in the same way that it takes to protect its Confidential Information of the same nature and to prevent them before any unauthorized use or disclosure of or unauthorized attempts to access or modify.</p>
                  <p style={{ marginBottom: '1rem' }}>5.3. You may provide or we may ask you to provide us (or other entities offering Services integrated with us) from time to time enhancement requests, ideas, corrections, improvement, comments, surveys, suggestions or recommendations or other feedback regarding  the Services or the Sites operated by us (“Feedback”).  If you provide us with any Feedback, then you grant us a worldwide, non-exclusive, perpetual, irrevocable, transferable, sublicensable, royalty-free right to use, reproduce, distribute, publicly display, perform or otherwise exploit and incorporate Feedback in whole or in part into the Services or Sites and otherwise dispose of and support our products and services embodying such Feedback in any manner and via any media as we see fit.This right is for the purpose of operating, developing, providing, promoting, and improving the Services or Sites including researching, developing new ones, and assisting us in our marketing efforts.</p>
                  <p style={{ marginBottom: '1rem' }}>5.4. The Client shall indemnify, defend, and hold harmless ChatQ from and against any and all losses, damages, demands, claims, actions, liabilities, fines, penalties, and related expenses (including reasonable legal fees) asserted against or incurred by ChatQ that arise out of, or result from, the Client Data and in relation to any and all claims related to the breach of the confidentiality obligations, infringement of any patent, copyright, trademark, or trade secret right, or other intellectual property rights, private right, or any other proprietary or personal interest of any person or entity violated by the Client Data.</p>

                  <h2 className="text-3xl font-bold tracking-tight my-8 self-center pb-4">6. Fees and Payment Terms</h2>
                  <p style={{ marginBottom: '1rem' }}>6.1. You acknowledge and agree to pay fees in accordance with the then-current effective rates placed on the Sites applicable for your Services unless otherwise set forth in an Order Form, if any.</p>
                  <p style={{ marginBottom: '1rem' }}>6.2. The usage of the Services available on the particular Sites is free of charge for some period of time depending on the Services.</p>
                  <p style={{ marginBottom: '1rem' }}>6.3. All payments shall be non-cash transactions, conducted electronically by external professional entities. The Client acknowledges and agrees that ChatQ shall send invoices and payment reminders exclusively by electronic means. The receipts for all payments will be provided electronically and stored in the Client’s Account.</p>
                  <p style={{ marginBottom: '1rem' }}>6.4. If ChatQ has not received payment within the due date set forth in the invoice and without prejudice to any other rights and remedies of ChatQ under this Agreement or law, we may, without liability to the Client: (i) suspend access, including by disabling the Client’s password and Account, to all or part of the Services and we shall be under no obligation to provide any or all of the Services while the invoice(s) concerned remain unpaid.</p>
                  <p style={{ marginBottom: '1rem' }}>6.5. The Client is responsible for providing complete and accurate billing and contact information to us and notifying us of any changes to such information.</p>
                  <p style={{ marginBottom: '1rem' }}>6.6. In addition, ChatQ, at its sole discretion, may change the remuneration with prior notice, including, without limitation, in the event of further development and/or the expansion of the Services offered by ChatQ.</p>
                  <p style={{ marginBottom: '1rem' }}>6.7. Unless expressly agreed otherwise, all amounts stated in the Agreement or Order Form or the price list are exclusive of any taxes, levies, duties or similar governmental assessments of any nature, other charges, domestic or foreign-imposed by any federal, state, or local tax authority with respect thereto including, for example, value-added, sales, use or withholding taxes, assessable by any jurisdiction whatsoever (collectively, “Taxes”). The Client is responsible for paying all Taxes associated with the Client’s purchases hereunder. All fees payable by the Client shall be paid without any right of counterclaim or set-off, and without any deduction or withholding on any grounds whatsoever, save only as required under applicable law. If any such deduction or withholding is required under applicable law, the Client shall pay the amount necessary to ensure ChatQ will, after any such deduction or withholding has been made, receive an amount equal to the amount ChatQ would have received absent of such deduction or withholding. All fees payable by the Client for Services in line with the current price plan, unless otherwise agreed, will be increased with the amount of any applicable tax. If ChatQ has the legal obligation to pay or collect Taxes for which the Client is responsible under Clause 6, ChatQ will issue an invoice to the Client and the Client will pay that amount unless the Client provides ChatQ with a valid tax exemption certificate authorized by the appropriate taxing authority.</p>

                  <h2 className="text-3xl font-bold tracking-tight my-8 self-center pb-4">7. Subscription</h2>
                <p style={{ marginBottom: '1rem' }}>7.1. Regardless of the billing cycle, we provide no refunds, no right of set-off, or credits for any unused time of the Services for whatever reason prior to the end of the Subscription Period (as defined below), breach of this Agreement and/or applicable law.</p>
                <p style={{ marginBottom: '1rem' }}>7.2. There will be no exceptions in order to treat all Clients equally and to keep our administrative costs low for the ultimate benefit of our Clients.</p>
                <p style={{ marginBottom: '1rem' }}>7.3. Subscription availability details are as follows:</p>
                <p style={{ marginBottom: '1rem' }}>7.3.1. Trial Services</p>

                <ul style={{ listStyleType: 'decimal', listStylePosition: 'outside', paddingLeft: '2rem', marginTop: '1rem', marginBottom: '1rem' }}>
                  <li style={{ marginBottom: '0.75rem' }}>After signing up, the Client gets access to a new, fully-functional trial Account without charge and valid for some period (depending on Services) to get to know Services well before subscribing to Paid Subscription. Trial Services are subject to the Agreement. During a trial, a credit card is not required.</li>
                  <li style={{ marginBottom: '0.75rem' }}>To be eligible for the Trial Services, you must be a new Client and sign up for the Services for the first time. Trial Services are provided on an “as-is” and “as - available” basis without any warranty of any kind not expressly stated therein, and the Company does not represent or warrant to the Client that: (a) Client’s use of the Trial Services will meet Client’s requirements, (b) Client’s use of the Trial Services will be uninterrupted, accurate, secure, accessible or free from error.</li>
                  <li style={{ marginBottom: '0.75rem' }}>The Client agrees that the Company will not be liable to the Client or any third party arising out of, or in connection with Trial Services. In particular, the Company shall have no indemnification obligations, nor liability of any type, with respect to the Trial Services unless such exclusion of liability is not enforceable under applicable law, in which case the aggregated Company’s liability (section 10) with respect to the Trial Services shall in no event exceed $1,000.00.</li>
                  <li style={{ marginBottom: '0.75rem' }}>Notwithstanding anything to the contrary in points b. and c. above, the Client shall be fully liable to the Company for any damages arising out of Client’s use of the Trial Services, any breach by Client of the Agreement, and any of Client’s indemnification obligations hereunder.</li>
                </ul>
              <p style={{ marginBottom: '1rem' }}>7.3.2. Free of charge Services: If the Client uses any Services that are free of charge, all the provisions applicable to trial Accounts apply accordingly.</p>
              <ul style={{ listStyleType: 'decimal', listStylePosition: 'outside', paddingLeft: '2rem', marginTop: '1rem', marginBottom: '1rem' }}>
                <li style={{ marginBottom: '0.75rem' }}>Upon the end of Trial Services, if the Client wishes to continue using the Services, the Client will be requested to provide payment details to make a subscription fee according to the currently effective rates placed on the applicable Sites. The Client is responsible for all charges in its Account. We do not offer refunds and/or pro-rata refunds on already processed payments and this policy is the same for all Clients.</li>
                <li style={{ marginBottom: '0.75rem' }}>The Agreement shall be concluded for the term specified by the Client or as defined in an applicable Order Form (“Subscription Period”). The term of the Agreement is automatically extended for subsequent periods equal to the expiring Subscription Period unless otherwise agreed or the Account has been closed.</li>
                <li style={{ marginBottom: '0.75rem' }}>Paid services may be discontinued at any time:
                  <ol style={{ listStyleType: 'lower-roman', listStylePosition: 'outside', paddingLeft: '2rem', marginTop: '1rem', marginBottom: '1rem' }}>
                    <li>by you by closing your Account. Closing the Account means that further use of the Services using the existing password and login will no longer be possible, and after closing the Client Account, it will cease to work immediately.</li>
                    <li>we can close or suspend the Paid Services or any part or function thereof, without prior notice to you, due to a breach of any provision of this Agreement, applicable law, as well as faulty or unlawful use of the Services by the Client, its Agents or End Users.</li>
                  </ol>
                  None of the above methods of closing the Account or its suspension before the end of the Subscription Period for which the payment was made shall oblige ChatQ to return the amount for the unused period.
                </li>
                <li style={{ marginBottom: '0.75rem' }}>In any event, upon the Client’s written request, ChatQ shall return or delete requested Personal Data to the Client within (thirty) 30 days in the standard format accepted by ChatQ and in accordance with ChatQ’s data retention policy, unless it is unworkable due to technical issues or the purposes of the processing.</li>
                <li style={{ marginBottom: '0.75rem' }}>ChatQ has no liability of any kind for any damages suffered by the Client, its Agents or End-Users, or any other third party arising due to the suspension or closing of the Account by the Client, or by the Company.</li>
              </ul>
              <h2 className="text-3xl font-bold tracking-tight my-8 self-center pb-4">8. Privacy and Data Protection</h2>
                <p style={{ marginBottom: '1rem' }}>8.1. As a condition of using the Services, the Client is solely responsible for:</p>
                <ul style={{ listStyleType: 'decimal', listStylePosition: 'outside', paddingLeft: '2rem', marginTop: '1rem', marginBottom: '1rem' }}>
                  <li style={{ marginBottom: '0.75rem' }}>(a) the processing of Personal Data (including but not limited to its transfer) in accordance with the applicable law, including for obtaining applicable consents or having another basis for Personal Data processing,</li>
                  <li style={{ marginBottom: '0.75rem' }}>(b) obtaining all consents and authorizations as may be required by any applicable law, for the collection, storage, processing and transferring of Personal Data by ChatQ and its Sub-Processors according to Client’s instructions,</li>
                  <li style={{ marginBottom: '0.75rem' }}>(c) for the accuracy, quality, and legality of Personal Data and the means by which Client acquired them and</li>
                  <li style={{ marginBottom: '0.75rem' }}>(d) Client’s instructions for the Processing of Personal Data shall comply with applicable data protection law. In addition, the Client shall (a) as required by applicable law, provide notice to its End-Users and Agents, as well as obtain their consent (if required) or have other legal bases for processing and transferring Agents and End-Users personal data to ChatQ and its Sub-Processors. Processing of Personal Data by ChatQ or its Sub-Processors through the Services on behalf of the Client pursuant to, or in connection with this Agreement is executed by Data Processing Addendum (“DPA”).</li>
                </ul>
                <p style={{ marginBottom: '1rem' }}>8.2. For the purpose of providing Services, the ongoing operation thereof, and/or for security or legal purposes, you acknowledge and agree that we (i) collect, process, store all data when you connect to, access and/or use Services and (ii) access your Account(s) and Client Data during the subscription.</p>
                <p style={{ marginBottom: '1rem' }}>8.3. ChatQ respects your privacy and is committed to protecting the information you share with us. ChatQ will never sell, rent, or lease your data to any third party. We will not share your Client Data with third parties, except as permitted by the Agreement and in order to provide, secure, and support the Services or as required by the provisions of applicable law. ChatQ believes that you have a right to know our practices regarding the information ChatQ collects when you connect to, access or use the Services. Our policy and practices and the type of information collected are further described in our Privacy Policy which is hereby incorporated into this Agreement.</p>
                <p style={{ marginBottom: '1rem' }}>8.4. Any observation or breach of data protection may be reported via support@ChatQ.com (or via support e-mail of the Services you use).</p>
                <h2 className="text-3xl font-bold tracking-tight my-8 self-center pb-4">9. Guarantee</h2>
                <p style={{ marginBottom: '1rem' }}>9.1. We represent and warrant to the Client that (i) during the term of the Agreement or Order Form, ChatQ will perform its operations in a manner to ensure accessibility and continuity of offered Services in accordance with their use and purpose and (ii) any support performed by or on behalf of ChatQ under the Agreement, if any, will be performed in a professional and workmanlike manner and by personnel that has the necessary skills, training and background to perform such support.</p>
                <p style={{ marginBottom: '1rem' }}>9.2. You acknowledge and agree that Services or any portion of it and any Content accessed by you through the Sites is (i) provided on an “as is” and “as available” basis and may change over time at our sole discretion and (ii) without any warranties of any kind whether express or implied, including, without limitation, any implied warranties of merchantability, fitness for a particular purpose, title or arising by a course of dealing or usage of trade. In particular, due to the complexity of long-distance data transmission, there is no possibility of ensuring absolute accuracy, security, accessibility, integrity and continuity of the provided Services. For this reason, to the fullest extent permitted by applicable law, we do not make or give any representation or warranty that our Services, Sites or Content, or any portion of them will always be available, accessible or uninterrupted, timely, secure, error-free or free from viruses or other malicious software, that any error, bug or problem be resolved or that they will meet the Client’s requirements and no information or advice obtained by you from us or through the Services shall create any warranty not expressly stated in the Agreement.</p>
                <p style={{ marginBottom: '1rem' }}>9.3. In no event will ChatQ have any obligation or liability arising from (i) use or inability to use of any Services in a modified form or in combination with materials not furnished by ChatQ; (ii) statements or conduct of any third party on or in the Services, (iii) any Client Data (iii) any failure by Client to comply with Client’s responsibilities under this Agreement; and (iv) use by ChatQ of any equipment provided by Client and per Client’s instructions, for the provision of any support or implementation services, (v) damages suffered by the Client, Agents or End-Users, or any other person or entity having arisen due to the third-party claims, suspension or closing of the Account by the Client or the Company, or for other reasons arising from the Client’s fault; (vi) damage incurred by the Client, Agents, End-Users, or any other person or entity as a result of Client’s third-party usage of Services that enable or prevent the Client or Agents or End-Users from accessing the provided Services; (viii) damages caused by the Client or the impossibility to use Services, incidental and consequential damages, including damage actually suffered, the loss of expected profits/benefits, data loss, damage or computer crash, the costs of substitute equipment and software, shut-down, or company reputation infringement;</p>
                <p style={{ marginBottom: '1rem' }}>9.4. ChatQ advises the Client that a number of factors outside of ChatQs control may arise and impact the quality of our Services and your access and/use of our Services, including, without limitation, actions of third parties who do not act on behalf of ChatQ, force majeure, fires, strikes, accidents, pandemic, and technical conditions beyond the control of ChatQ, e.g. the Internet. The hardware, software, and technical infrastructure used by the Client can also influence the Services. Any delay or default affecting the availability, functionality, or timely performance of the Services caused by such circumstances will not constitute a breach of the Agreement.</p>
                <p style={{ marginBottom: '1rem' }}>9.5. Under this Clause 9,  in the event of non-conformance with the guarantee, Company shall, at its sole discretion, use commercially reasonable efforts to correct such non-conformance by repairing or re-performing or removing it. THESE REMEDIES SHALL BE THE CLIENT’S SOLE AND EXCLUSIVE REMEDY AND ChatQ’S ENTIRE LIABILITY FOR ANY BREACH OF THE LIMITED GUARANTEE UNDER THIS CLAUSE.</p>
                <h2 className="text-3xl font-bold tracking-tight my-8 self-center pb-4">10. Liability</h2>
                <p style={{ marginBottom: '1rem' }}>10.1 Limitation on Indirect Liability. To the fullest extent permitted by law, in no event shall ChatQ be liable under this Agreement for any indirect, incidental, special, consequential or exemplary, or punitive damages, including but not limited to, damages for loss of profits, goodwill, use, business, revenues, data or other intangible losses, in each case, even if the party knew or should have known that such damages were possible and even if a remedy fails of its essential purpose.</p>
                <p style={{ marginBottom: '1rem' }}>10.2 Limitation on amount of Liability. ChatQ shall only be liable for direct damages excluding any situation for which we are not responsible or which are caused by events outside our reasonable control. However, in no event, shall ChatQ’s aggregate liability under the Agreement or an applicable Order Form exceed the total amount paid by the Client to ChatQ hereunder for use of the paid Services during the maximum 12 (twelve) months prior to the liability event. The foregoing limitation will not limit the Client’s payment obligations under the Agreement.</p>
<h2 className="text-3xl font-bold tracking-tight my-8 self-center pb-4">11. Final provisions</h2>
                <p style={{ marginBottom: '1rem' }}>11.1 If any term or provision of the Agreement is invalid, illegal, or unenforceable in any jurisdiction, such invalidity, illegality, or unenforceability shall not affect any other term or provision of the Agreement or invalidate or render unenforceable such term or provision in any other jurisdiction.</p>
                <p style={{ marginBottom: '1rem' }}>11.2 The term of any document applicable to the Client will follow the term of the Agreement or Order Form. If there is any conflict between different ChatQ’s terms, the following rules must be applied: a) the provisions of Order Form (if any) take precedence over those set out in the Agreement to the extent of such conflict or inconsistency; b) the provisions set out in DPA related to Personal Data will take precedence over the terms of the Agreement to the extent of such conflict or inconsistency.</p>
                <p style={{ marginBottom: '1rem' }}>11.3 Governing Law. The Agreement and all matters relating to it shall be governed by the laws of the State of Massachusetts, United States of America, without giving effect to any principles of conflicts of law. The sole and exclusive jurisdiction and venue for any suit, action, or proceeding arising out of the Agreement shall be an appropriate federal or state court located in the State of Massachusetts, and the Parties agree not to raise, and hereby waive, any objections or defences based upon venue or forum non-conveniens. Any claims, disputes, disagreements or other matters in question arising out of or relating to this Agreement (“Claim”) shall be primarily resolved amicably by mediation within thirty (30) days of the receipt of such notice.</p>
          </div>
        </div>
        {/* empty div to evenly justify content above against */}
        <div />
      </div>
      <NavigationFooter />
    </div>
  )
}

export default TermsOfService