import { useNavigate, NavLink } from "react-router-dom"
import NavigationHeader from "../Navigation/NavigationHeader"
import NavigationFooter from "../Navigation/NavigationFooter"
import '../MyChatbots/Chatbot.css'
import { useMyChatbotsValue } from "../MyChatbots/MyChatbotsContext"

export type FeaturedChatbot = {
  name: string
  id: string
  image?: string | null
  initialMessage: string
}

// TODO move this to the backend, only store ids on frontend & fetch additional data in FeaturedChatbot.tsx
export const FEATURED_CHATBOTS: FeaturedChatbot[] = [
  { name: 'Elon Musk on Mars', id: 'AwpCBhh2Oxlo7BcL0BSw', image: require("../../assets/images/elon_musk.jpg"), initialMessage: "I am a chatbot trained on the full text of Elon Musk's interviews on sending humands to Mars. What would you like to know?" },
  { name: 'Romeo and Juliet', id: 'yeiBQyWl73UIheEjyrsN', image: require("../../assets/images/romeo_juliet.jpg"), initialMessage: "I am a chatbot trained on the full text of Romeo and Juliet. What would you like to know?" },
  { name: 'Harry Potter and the Sorcerer\'s Stone', id: 'IMGzB1ikhgXJ0tSjvQhp', image: require("../../assets/images/harry_potter.jpg"), initialMessage: "I am a chatbot trained on the full text of Harry Potter and the Sorcerer's Stone. What would you like to know?" },
  { name: 'Apple 2023 Earnings Report', id: '78tb1TbJVf8r1X22FzcJ', image: require("../../assets/images/apple.jpg"), initialMessage: "I am a chatbot trained on the full text of the Apple 2023 Earnings report. What would you like to know?" },
  { name: 'US Constitution', id: 'g61AsIe759QPYbS5Qpue', image: require("../../assets/images/us_constitution.jpg"), initialMessage: "I am a chatbot trained on the full text of the US Constitution. What would you like to know?" },
  { name: 'ChatQ', id: 'c0K0nXo8lk9ur7mVCnv4', image: require("../../assets/images/chatq_updated_logo.png"), initialMessage: "👋 I am a chatbot trained to explain what ChatQ is and how it can boost your Shopify sales and customer experience. What would you like to know?" },
  { name: 'Sam Altman Senate Testimony Chatbot 🤖', id: 'AZQlZj1KnOEq8Rw1RNIs', image: require("../../assets/images/sam_altman_senate_testimony.jpg"), initialMessage: "I am a chatbot trained on Sam Altman's May 16th Senate testimony. What would you like to know?" },
]

const FeaturedChatbots = () => {
  const navigate = useNavigate()
  const [myChatbots] = useMyChatbotsValue()
  const userHasChatbots = !!myChatbots.length

  return (
    <>
      <div className="min-h-screen flex flex-col px-8">
        <NavigationHeader />
        <div className="flex flex-col flex-1 items-center justify-evenly">
          <div>
            {/* <h1 className="text-5xl text-center font-bold tracking-tight mt-8 mb-4">Featured Chatbots</h1> */}
            <p className="text-md text-center text-slate-500 mb-6">Try out some of our featured chatbots!</p>
            <div className="flex flex-wrap justify-center items-center">
              {FEATURED_CHATBOTS.map(chatbot => {
                // if first word in a title is over 11 chars, we assume it's the default assigned title in the form of chatbotID or web url. 
                // Truncate the non-meaningful title to keep the UI clean + prevent overflow text
                const truncateLongTitle = chatbot.name.split(" ")[0].length > 11
                return (
                  <button
                    onClick={() => navigate(`/featured-chatbots/${chatbot.id}`)}
                    className="flex flex-col justify-between rounded-lg w-40 m-4 mx-6 p-0 shadow-md"
                    key={chatbot.name}
                  >
                    {chatbot.image ? <img className="w-40 h-48 object-cover rounded-t-lg" src={chatbot.image} alt="chatbot_image" /> : <div className="rounded-lg chatbot-default-background" />}
                    <p className={`w-40 flex-1 border-t border-t-slate-300 text-md p-4 text-left tracking-tight font-medium ${truncateLongTitle ? 'truncate' : ""}`}>{chatbot.name}</p>
                  </button>
                )
              })}
            </div>
          </div>
          <div className="flex flex-col items-center">
            <NavLink to={userHasChatbots ? "/my-chatbots" : "/new-chatbot"} className="h-full text-xl shadow rounded-lg bg-black text-white my-8 py-3 px-5 font-medium">{userHasChatbots ? 'Go to my chatbots' : 'Create your first chatbot'}</NavLink>
          </div>
          {/* empty div to evenly justify content above against */}
          <div />
        </div>
      </div>
      <NavigationFooter />
    </>
  )

}

export default FeaturedChatbots