import { NavLink } from "react-router-dom"
import { useState } from "react"
import NavigationHeader from "../Navigation/NavigationHeader"
import NavigationFooter from "../Navigation/NavigationFooter"
import ChatbotChatbox from "../Chatbot/ChatbotChatbox"
import { RemainingMessagesDisplay, parseIntroMessage } from "../Chatbot/Chatbot"
import { FEATURED_CHATBOTS } from "../FeaturedChatbots/FeaturedChatbots"
import FAQAccordion from './FAQAccordion'


const Home = () => {
  const [remainingMessagesCount, setRemainingMessagesCount] = useState<number | null>(null)
  const chatQChatbot = FEATURED_CHATBOTS.find(c => c.name === 'ChatQ')
  return (
    <div className="flex flex-col">
      <div className="home-screen flex flex-col justify-between px-4">
        <NavigationHeader />
        <div className="flex flex-col text-center items-center">
        <h1 className="text-5xl md:text-7xl font-semibold mt-[-10px]">The most powerful</h1>
        <h1 className="text-5xl md:text-7xl font-semibold text-accent-blue">
          <span className="md:text-8xl">chatbot for Shopify.</span>
        </h1>
        {/* <h1 className="text-5xl md:text-7xl font-semibold">sales and returns.</h1> */}
          <div className="flex flex-col items-center max-w-xl">
            <p className="text-slate-500 text-xl pt-14 tracking-tight">Build chatbots that can automate return, order status and product sales questions from customers, better and faster than humans.</p>
            <NavLink to="/my-chatbots" className="h-full text-xl shadow rounded-lg bg-gradient-to-tr from-accent-turquoise to-accent-blue text-white mt-14 py-3 px-5 font-medium">Try it out for free 🔥</NavLink>
          </div>
        </div>
        <p className="text-2xl text-center font-medium tracking-tight mt-[-10px]"></p>
        {/* <p className="text-2xl text-center font-medium tracking-tight py-6">Video Demo &darr;</p> */}
      </div>
      {/* <video id="myVideo" controls autoPlay muted className="pb-16 mx-auto h-[550px] w-full md:w-[950px]">
        <source src="https://firebasestorage.googleapis.com/v0/b/custom-gpt-ceb44.appspot.com/o/videos%2FChatQ_Demo_151323_6.mp4?alt=media&token=3bc0e362-d5e9-452b-8abf-844c49202016" />
      </video> */}
      <p className="text-2xl text-center font-medium tracking-tight py-6">Live Demo</p>
      <div className="flex flex-col md:w-[750px] lg:w-[950px] self-center mx-4">
        <div className="h-[550px] rounded-md border border-slate-300">
          <ChatbotChatbox introMessages={parseIntroMessage(chatQChatbot!.initialMessage)} displayName={chatQChatbot!.name}
            chatbotId={chatQChatbot!.id} setRemainingMessagesCount={setRemainingMessagesCount} />
        </div>
        {remainingMessagesCount && <RemainingMessagesDisplay count={remainingMessagesCount} />}
      </div>
      <FAQAccordion/>
      <NavigationFooter />
    </div>
  )
}

export default Home
