import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { Grid } from "react-loader-spinner"
import { BASE_FETCH_URL } from "../../App"
import { useUserValue } from "../Auth/UserContext"
import ChatbotChatbox from "./ChatbotChatbox"
import { ChatbotSettingsResponse, DEFAULT_INTRO_MESSAGE, parseIntroMessage } from "./Chatbot"

const ChatbotIframe = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const userId = urlParams.get('userId')
  const { userAuth } = useUserValue()
  const origin = window.location.origin
  const { chatbotId } = useParams()
  const [displayName, setDisplayName] = useState('')
  const [introMessage, setIntroMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [accessForbidden, setAccessForbidden] = useState(false)
  const [fetchError, setFetchError] = useState<string | null>(null)


  useEffect(() => {
    const fetchEmbeddedChatbotSettings = async () => {
      setLoading(true)
      try {
        const token = userAuth ? await userAuth.getIdToken() : null
        const headers: Record<string, string> = {
          'Content-Type': 'application/json',
          'accept': 'application/json',
        }
        headers['Authorization'] = `Bearer ${token ? token : userId}`
  
        const requestOptions = {
          method: 'POST',
          headers,
          body: JSON.stringify({
            chatbotId: chatbotId,
          }),
        }
        const response = await fetch(
          `${BASE_FETCH_URL}/api/v1/get-chatbot-settings`,
          requestOptions
        )
        if (!response.ok) {
          if (response.status === 403) {
            setAccessForbidden(true)
          } else {
            throw new Error(response.status + ': ' + response.statusText)
          }
        }
        // console.log(`response.status: ${response.status}`)
        const settings: ChatbotSettingsResponse = await response.json()
        // console.log(`settings: ${settings}`)
        setDisplayName(settings.chat_window_display_name ?? '')
        setIntroMessage(settings.initial_message ?? DEFAULT_INTRO_MESSAGE)
        setLoading(false)
      } catch (e: any) {
        setFetchError(`${e}`)
        setLoading(false)
      }
    }
    fetchEmbeddedChatbotSettings()
  }, [chatbotId, userAuth, userId])
  

  if (!chatbotId) {
    return (
      <div className="w-full h-screen flex flex-col justify-center items-center text-center bg-white p-8 rounded-lg">
        <h2 className="text-md font-semibold mb-2">Chatbot not found</h2>
        <h3 className="text-xs text-slate-600 mb-1">Something went wrong with embedding your chatbot.</h3>
        <p className="text-xs text-slate-600 mb-1">Double check the id provided to the embedded script tag matches your chatbot ID.</p>
      </div>
    )
  }

  if (loading) {
    return <div className="w-full flex flex-col items-center">
      <Grid
        height="30"
        width="30"
        color="#7398E8"
        ariaLabel="loading"
        radius="12"
        wrapperClass="mt-24"
        visible
      />
    </div>
  }

  if (accessForbidden) {
    return (
      <div className="w-full h-screen flex flex-col justify-center items-center text-center bg-white p-8 rounded-lg">
        <h2 className="text-md font-semibold mb-2">Access Forbidden</h2>
        <h3 className="text-xs text-slate-600 mb-1">The website {origin} does not have the right permissions to display this chatbot.</h3>
        <p className="text-xs text-slate-600 mb-1">Please make sure the chatbot owner has either listed the chatbot as public, or has listed this website as a whitelisted domain on the chatbot settings page.</p>
      </div>
    )
  }

  if (fetchError) {
    return (
      <div className="w-full h-screen flex flex-col justify-center items-center text-center bg-white p-8 rounded-lg">
        <h2 className="text-md font-semibold mb-2">Something went wrong.</h2>
        <h3 className="text-xs text-slate-600 mb-1">Something went wrong with fetching the data for this chatbot.</h3>
        <p className="text-xs text-red-600 mb-1">{fetchError}</p>
      </div>
    )
  }

  return (
    <div className="h-screen w-full">
      <ChatbotChatbox displayName={displayName} 
                      introMessages={parseIntroMessage(introMessage)} 
                      chatbotId={chatbotId!} 
                      userId={userId ?? undefined} />
    </div>
  )
}

export default ChatbotIframe