import NavigationHeader from '../Navigation/NavigationHeader'
import NavigationFooter from '../Navigation/NavigationFooter'
// import chatQEmbedInstructions from './chatq_embed_instructions.jpg';

const HowItWorks = () => {
  return (
    <div>
      <div className="min-h-screen flex flex-col px-8">
        <NavigationHeader />
        <div className="flex flex-col flex-1 items-center justify-center">
          <div className="flex flex-col w-full max-w-4xl self-center">

            <h1 className="self-center font-bold text-5xl mb-10 tracking-tight">How It Works</h1>
            <div className="mb-6">
              <h2 className="text-3xl font-semibold mb-6">📋 Step by Step</h2>
              <p className="text-slate-500 mt-3 mb-8">
              Creating and deploying your chatbot is easy with ChatQ AI. Follow the below step-by-step instructions to create your chatbot for your store and deploy it to start answering customer questions:
              </p>
              <h2 className="text-3xl font-semibold mb-6">🤖 Creating Your Chatbot </h2>
              <ol className="list-decimal list-outside text-gray-700 pl-4">
                <li className="text-slate-500 mt-3">    • Go to the <strong>Chatbot Builder</strong> under the <strong>My Chatbots</strong> page.</li>
                <li className="text-slate-500 mt-3">    • Click on <strong>Add new Chatbot</strong>.</li>
                <li className="text-slate-500 mt-3">    • Select <strong>Website</strong> as the platform.</li>
                <li className="text-slate-500 mt-3">    • Paste your Shopify store URL.</li>
                <li className="text-slate-500 mt-3">    • Wait for your page to be scraped and the chatbot to be created.</li>
              </ol>
              
              <h2 className="text-3xl font-semibold mb-6 mt-12">🚀 Embedding Your Chatbot </h2>
              <ol className="list-decimal list-outside text-gray-700 pl-4" start={6}>
                <li className="text-slate-500 mt-3">    • Return to <strong>My Chatbots</strong> and click on the tile of your new chatbot.</li>
                <li className="text-slate-500 mt-3">    • Click on the <strong>Embed</strong> tab of your chatbot.</li>
                <li className="text-slate-500 mt-3">    • Copy the provided <strong>&lt;script&gt;</strong> tag.</li>
                <li className="text-slate-500 mt-3">    • Go to your Shopify Admin dashboard and select <strong>Themes</strong> under <strong>Online Store</strong>.</li>
                <li className="text-slate-500 mt-3">    • Click on <strong>Edit Code</strong> of your current theme.</li>
                <li className="text-slate-500 mt-3">    • Select the <strong>theme.liquid</strong> file.</li>
                <li className="text-slate-500 mt-3">    • Insert the copied script code right before the closing <strong>&lt;/body&gt;</strong> tag of this page.</li>
                {/* Image display */}
                <img src={"./chatq_embed_instructions.jpg"} alt="ChatQ Embed Instructions" className="mt-3 mb-8"/>
              </ol>
              <p className="text-slate-500 mt-3 mb-8">
              🎉 Congratulations! 🎉 Your ChatQ AI chatbot is now active on your Shopify store, ready to assist your customers.
              </p>
            </div>
          </div>
          <div />
        </div>
      </div>
      <NavigationFooter />
    </div>
  )
}

export default HowItWorks
