import { useNavigate } from 'react-router-dom'
import { NavLink } from "react-router-dom"
import { UserPlusIcon } from '@heroicons/react/24/outline'
import NavigationHeader from '../Navigation/NavigationHeader'
import NavigationFooter from '../Navigation/NavigationFooter'
import './Chatbot.css'
import { useUserValue } from '../Auth/UserContext'
import { useMyChatbotsValue } from './MyChatbotsContext'

export type Chatbot = {
  name: string
  id: string
  // not yet provided by the backend
  image?: string | null
}

const ChatbotsList = ({ myChatbots, maxChatbots }: { myChatbots: Chatbot[], maxChatbots: number }) => {
  const navigate = useNavigate()
  return (
    <div className="flex items-center flex-wrap justify-center">
      {myChatbots.map((chatbot: Chatbot, index) => {
        // if first word in a title is over 11 chars, we assume it's the default assigned title in the form of chatbotID or web url. 
        // Truncate the non-meaningful title to keep the UI clean + prevent overflow text
        const truncateLongTitle = chatbot.name.split(" ")[0].length > 11
        return (
          <button onClick={() => navigate(`/chatbot/${chatbot.id}`)} className="flex flex-col justify-between rounded-lg h-56 w-40 m-4 p-0 shadow-md " key={`${chatbot.name + index}`}>
            {chatbot.image ? <img height="100%" width="100%" src={chatbot.image} alt="chatbot_image" /> : <div className="rounded-lg chatbot-default-background" />}
            <p className={`w-full border-t border-t-slate-300 text-md p-4 text-left tracking-tight font-medium ${truncateLongTitle ? 'truncate' : ""}`}>{chatbot.name}</p>
          </button>
        )
      })}
      {myChatbots.length < maxChatbots ?
        <button onClick={() => navigate('/new-chatbot')} className="flex flex-col justify-center items-center rounded-lg border border border-dashed border-slate-300 h-56 w-40 m-4 p-0 shadow-md" key="new-chatbot">
          <UserPlusIcon className="h-6 w-6" />
          <p className="w-full text-md p-4 tracking-tight">Add new chatbot</p>
        </button> : null}
    </div>
  )
}

function MyChatbots() {
  const { userAuth, userSettings } = useUserValue()
  const maxChatbots = userSettings?.maxChatbots ?? 1
  const [myChatbots] = useMyChatbotsValue()

  return (
    <>
      <div className="min-h-screen flex flex-col px-8">
        <NavigationHeader />
        <div className="flex flex-col flex-1 items-center justify-evenly">
          <div className="flex flex-col items-center">

            <h1 className="text-5xl font-bold tracking-tight mt-8 mb-3 text-center">My Chatbots</h1>
            {userSettings?.maxChatbots && <p className="tracking-tight mb-8 text-sm text-slate-500 italic">{`(Max chatbots: ${userSettings.maxChatbots})`}</p>}
            {
              !userAuth ? (
                <>
                  <p className="text-md text-slate-600">
                    Please log in or create an account to create and view your own
                    chatbots.
                  </p>
                  <div className="flex flex-col items-center">
                    {/* Updated classes for the NavLink component */}
                    <NavLink
                      to="/login"
                      className="h-full text-xl shadow rounded-lg bg-black text-white mt-14 py-3 px-5 font-medium"
                    >
                      Start free trial
                    </NavLink>
                  </div>
                </>
              ) : (
                <ChatbotsList maxChatbots={maxChatbots} myChatbots={myChatbots} />
              )
            }

          </div>
          {/* empty div to evenly justify content above against */}
          <div />
        </div>
      </div>
      <NavigationFooter />
    </>

  )
}

export default MyChatbots
