import { useState } from "react"
import { Oval } from "react-loader-spinner"
import { logEvent } from "firebase/analytics"
import { analytics } from '../Auth/firebase'
import { BASE_FETCH_URL } from "../../App"
import { useUserValue } from "../Auth/UserContext"
import { Chatbot } from "../MyChatbots/MyChatbots"
import { DataImportProps } from "./CreateNewChatbot"


const WebsiteImport = ({ goBack, navigateToChatbot }: DataImportProps) => {

  const [website, setWebsite] = useState<string>('')
  const { userAuth } = useUserValue()
  const [submitInProgress, setSubmitInProgress] = useState(false)
  const [error, setError] = useState('')

  const handleSubmit = async () => {
    setSubmitInProgress(true)
    const token = await userAuth?.getIdToken()
    try {
      const requestBody = {
        url: website,
      }

      const response = await fetch(`${BASE_FETCH_URL}/api/v1/create-chatbot-website`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      })
      if (response.ok) {
        const jsonResponse = await response.json()
        const newChatbot: Chatbot = {
          name: jsonResponse.chatbotName,
          id: jsonResponse.chatbotId,
          image: jsonResponse.chatbotImage
        }
        navigateToChatbot(newChatbot.id)
        logEvent(analytics, 'success_create_chatbot_website')
      } else {
        setSubmitInProgress(false)
        if (response.status === 401) {
          throw new Error(`${response.status} ${response.statusText}: Please log in first.`)
        }
        logEvent(analytics, 'failure_create_chatbot_website')
        throw new Error(response.status + ": " + response.statusText)
      }
    } catch (e: any) {
      setError(`${e}`)
      setSubmitInProgress(false)

    }
  }

  return (
    <div className="flex flex-col max-w-xl w-full">
      <button onClick={goBack} className="h-min self-start text-slate-500 bg-slate-100 rounded-lg mb-6"><p className="py-1.5 px-2 text-xs">&larr; back</p></button>
      {/* todo add regex to filter web url arg */}
      <h1 className="text-5xl font-bold tracking-tight text-center mb-6">Enter website url:</h1>
      <div className="flex flex-col sm:flex-row justify-center items-center">
        <input type="text" className="h-16 w-full sm:w-fit sm:flex-1 sm:mr-2 mb-4 text-md py-2 px-3 bg-slate-100 text-slate-500 rounded-lg" value={website} placeholder="https://..." onChange={(e) => setWebsite(e.target.value)} />
        <button disabled={!website || submitInProgress} onClick={handleSubmit} className={`${!website ? "opacity-40" : ""} flex justify-center items-center bg-black h-16 w-full sm:w-fit mb-4 self-center py-2 px-4 text-white text-md tracking-tight rounded-lg shadow-lg`}>{submitInProgress ? <Oval
          height={20}
          width={20}
          color="white"
          visible={true}
          ariaLabel='oval-loading'
          secondaryColor="white"
          strokeWidth={4}
          strokeWidthSecondary={4}
        /> : 'Create Chatbot'}</button>
      </div>
      {error && <p className="text-xs text-red-600 py-2">{error}</p>}
    </div >
  )
}

export default WebsiteImport

  // manual link add  TODO add regex to validate input
  // <div className='flex flex-row mb-2'>
  //   <input
  //     type="text"
  //     className="flex-1 text-xs border border-slate-200 rounded-lg p-2 focus:border-accent-blue focus:border-2"
  //     value={manualLinkInput}
  //     placeholder="https://example.com/path/to/webpage"
  //     onChange={(e) => setManualLinkInput(e.target.value)} />
  //   <button disabled={!manualLinkInput} onClick={handleAddManualLink} className={`${manualLinkInput ? "bg-black text-white" : "bg-slate-100 text-slate-500"} text-sm w-8 h-8 rounded-lg ml-2`}>+</button>
  // </div>

  // sitemap input, add in later
  // form validate this is actually a sitemap link
  // <p className="text-xs font-semibold mb-2">Load a sitemap</p>
  // < div className = 'flex flex-row mb-6' >
  //       <input type="text" className="flex-1 text-xs border border-slate-200 rounded-lg p-2 focus:border-accent-blue focus:border-2" value={website} placeholder="https://www.example.com/sitemap.xml" onChange={(e) => setSitemapUrl(e.target.value)} />
  //       <button
  //         onClick={() => { }} // generate links from sitemap and populate into websiteLinks array
  //         className="bg-black text-white text-xs px-4 py-2 rounded-lg ml-2">
  //         Generate Links
  //       </button>
  //     </div >

// individual links input, add in later
// todo form validate these are all valid links/paths
// <p className="text-sm mb-2">Links to include</p>
// {
//   websiteLinks.map((url, index) => {
//     return (
//       <div key={url}
//         className='flex flex-row mb-2'>
//         <input
//           type="text"
//           className="flex-1 text-xs border border-slate-200 rounded-lg p-2 focus:border-accent-blue focus:border-2"
//           value={url}
//           onChange={(e) => handleUpdateWebsiteLinks(e, index)} // edit existing link list
//         // TODO add delete button
//         />
//         <button disabled={!manualLinkInput} onClick={handleAddManualLink} className={"bg-slate-100 w-8 h-8 rounded-lg ml-2"}><TrashIcon className="h-3 w-3 text-red-600" /></button>
//       </div>

//     )
//   })
// }

// const handleUpdateWebsiteLinks = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
//   // update websiteLinks parent state based on whatever array index we're adding/modifying
//   setWebsiteLinks(existingLinks => {
//     const updatedLinks = [...existingLinks]
//     updatedLinks[index] = event.target.value
//     return updatedLinks
//   })
// }

// const handleAddManualLink = () => {
//   setWebsiteLinks(existingLinks => {
//     const updatedLinks = [...existingLinks, manualLinkInput]
//     return updatedLinks
//   })
// }