import NavigationFooter from "../Navigation/NavigationFooter"
import NavigationHeader from "../Navigation/NavigationHeader"

const NotFound = () => {
  return (
    <div>
      <div className="min-h-screen flex flex-col px-8">
        <NavigationHeader />
        <div className="flex flex-col flex-1 items-center justify-evenly">
          <div className="flex flex-col items-center">
            <p className="text-5xl font-bold mb-4">404</p>
            <p className="text-slate-500 text-sm">Sorry, we couldn&apos;t find the page you were looking for.</p>
          </div>
          {/* empty div to evenly justify content above against */}
          <div />
        </div>
      </div>
      <NavigationFooter />
    </div>
  )
}

export default NotFound