import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Oval } from "react-loader-spinner"
import { User } from "firebase/auth"
import { BASE_FETCH_URL, fetchUserChatbots } from "../../App"
import { useMyChatbotsValue } from "../MyChatbots/MyChatbotsContext"
import ChatbotChatbox from "./ChatbotChatbox"
import { DEFAULT_BASE_PROMPT, DEFAULT_INTRO_MESSAGE, TabName, parseIntroMessage, GptModel, models, } from "./Chatbot"

type ChatbotSettingsProps = {
  userAuth: User | null
  chatbotId: string
  name: string
  setName: React.Dispatch<React.SetStateAction<string>>
  displayName: string
  setDisplayName: React.Dispatch<React.SetStateAction<string>>
  unparsedIntroMessage: string
  setUnparsedIntroMessage: React.Dispatch<React.SetStateAction<string>>
  basePrompt: string
  setBasePrompt: React.Dispatch<React.SetStateAction<string>>
  model: GptModel
  setModel: React.Dispatch<React.SetStateAction<GptModel>>
  setActiveTab: React.Dispatch<React.SetStateAction<TabName>>
  settingsError: string | null
}

const ChatbotSettings = ({ userAuth, chatbotId, name, setName, displayName, setDisplayName, unparsedIntroMessage, setUnparsedIntroMessage, basePrompt, setBasePrompt, setActiveTab, settingsError, model, setModel }: ChatbotSettingsProps) => {
  // const [domains, setDomains] = useState('')
  // const [visibilitySelection, setVisibilitySelection] = useState(visibilitySelections[0])
  const [, setMyChatbots] = useMyChatbotsValue()
  const navigate = useNavigate()
  const [introMessageInput, setIntroMessageInput] = useState(unparsedIntroMessage)
  const parsedIntroMessages = parseIntroMessage(introMessageInput)
  const [deletionError, setDeletionError] = useState('')
  const [saveSettingsError, setSaveSettingsError] = useState('')
  const [saveChatbotSettingsLoading, setSaveChatbotSettingsLoading] = useState(false)
  const [deleteChatbotInProgress, setDeleteChatbotInProgress] = useState(false)

  async function saveChatbotSettings() {
    setSaveChatbotSettingsLoading(true)
    try {
      const token = await userAuth?.getIdToken()
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          chatbotId: chatbotId,
          chatbotName: name,
          base_prompt: basePrompt,
          model: model,
          chat_window_display_name: displayName,
          initial_message: introMessageInput
        }),
      }
      const response = await fetch(`${BASE_FETCH_URL}/api/v1/update-chatbot-settings`, requestOptions)

      if (!response.ok) {
        throw new Error(response.status + ": " + response.statusText)
      }
      setSaveChatbotSettingsLoading(false)
      setUnparsedIntroMessage(introMessageInput)
      setActiveTab('Chatbot')
    } catch (e: any) {
      setSaveSettingsError(e.message)
      setSaveChatbotSettingsLoading(false)
    }
  }

  async function deleteChatbot() {
    setDeleteChatbotInProgress(true)
    try {
      const token = await userAuth?.getIdToken()
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          chatbotId: chatbotId,
        }),
      }

      const response = await fetch(`${BASE_FETCH_URL}/api/v1/delete-chatbot`, requestOptions)
      setDeleteChatbotInProgress(true)
      if (!response.ok) {
        throw new Error(response.status + ": " + response.statusText)
      }
      await fetchUserChatbots(token).then(res => {
        if (!res.ok) {
          throw new Error(res.status + ": " + res.statusText)
        }
        res.json().then(jsonRes => {
          setMyChatbots(jsonRes.chatbots)
        })
      })
      navigate('/my-chatbots')
    } catch (e: any) {
      setDeletionError(e.message)
      setDeleteChatbotInProgress(true)
    }
  }

  return (
    <div className="pt-3 pb-8 max-w-3xl w-full self-center">
      <h2 className="sr-only">Settings</h2>
      {settingsError && <p className="text-xs text-red-600 pb-2">Error getting settings: {settingsError}</p>}

      {/* <p className="text-xs tracking-tight font-medium pb-1">Chatbot ID</p>
      <p className="text-xs font-semibold text-slate-600 tracking-wide mb-3">{chatbotId}</p> */}

      <p className="text-xs tracking-tight font-medium pb-1">Name</p>
      <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="border border-slate-200 p-1.5 text-xs text-slate-600 w-full rounded-sm mb-3" />

      <div className="flex items-center justify-between mb-1">
        <p className="text-xs tracking-tight font-medium self-end">Base Prompt</p>
        <button className="h-min text-slate-500 bg-slate-100 rounded-md"><p onClick={() => setBasePrompt(DEFAULT_BASE_PROMPT)} className="p-1 text-xs">Reset</p></button>
      </div>
      <textarea rows={4} value={basePrompt} onChange={(e) => setBasePrompt(e.target.value)} className="border border-slate-200 p-1.5 text-xs text-slate-600 w-full rounded-sm mb-3" />

      <p className="text-xs tracking-tight font-medium pb-1">Model</p>
      <select className="border border-slate-200 py-1.5 px-0.5 text-xs text-slate-600 rounded-sm mb-2" value={model} onChange={(e) => {
        // @ts-ignore value will always be GptModel type
        const val: GptModel = e.target.value
        setModel(val)
      }}
        id="model">
        {models.map(m => (
          <option key={m} value={m}>{m}</option>
        ))}
      </select>
      <p className="text-xs text-slate-400 mb-3">1 message using gpt-3.5-turbo costs 1 message credit. 1 message using gpt-4 costs 20 message credits.</p>

      {/* TODO 
      <p className="text-xs tracking-tight font-medium pb-1">Visibility</p>
      <select className="border border-slate-200 py-1.5 px-0.5 text-xs text-slate-600 rounded-sm mb-2" value={visibilitySelection} onChange={(e) => setVisibilitySelection(e.target.value)} id="visibility">
        {visibilitySelections.map(v => (
          <option key={v} value={v}>{v}</option>
        ))}
      </select>
      <p className="text-xs text-slate-400 pb-1">
        <span className="italic">Private</span>: No one can access your chatbot except you (your account).
      </p>
      <p className="text-xs text-slate-400 pb-1">
        <span className="italic">Private but can be embedded on website</span>: Other people can&apos;t access your chatbot if you send them the link, but you can still embed it on your website and your website visitors will be able to use it. (make sure to set your domains)
      </p>
      <p className="text-xs text-slate-400 pb-2">
        <span className="italic">Public</span>: Anyone with the link can access it on chatbase.co and can be embedded on your website.
      </p>
      <p className="text-xs text-slate-400 mb-3">
        Set to public if you want to be able to send a link of your chatbot to someone to try it.
      </p>

      <p className="text-xs tracking-tight font-medium pb-1">Domains</p>
      <textarea className="border border-slate-200 p-1.5 text-xs text-slate-600 w-full rounded-sm mb-1" rows={4} value={domains} onChange={(e) => setDomains(e.target.value)} />
      <p className="text-xs text-slate-400 pb-1">Enter each domain in a new line</p>
      <p className="text-xs text-slate-400 mb-6">Domains you want to embed your chatbot on. Your chatbot visibility has to be <span>Public</span> or <span>Private but can be embedded on website</span> for this to work.</p> */}

      <h2 className="text-lg font-medium tracking-tighter pb-2">Chat Interface</h2>
      <div className="flex flex-col md:flex-row">
        {/* chatbot display column */}
        <div className="flex h-[375px] max-w-sm self-start rounded-md border border-slate-300 mb-3 md:mb-0 md:mr-4 ">
          <ChatbotChatbox
            disableInputInteraction
            displayName={displayName}
            introMessages={parsedIntroMessages}
            chatbotId={chatbotId} />
        </div>
        {/* field column */}
        <div className="flex flex-1 flex-col justify-between md:ml-4">
          <div>
            <p className="text-xs tracking-tight font-medium pb-1">Chat Window Display Name</p>
            <input type="text" value={displayName} onChange={(e) => setDisplayName(e.target.value)} className="border border-slate-200 p-1.5 text-xs text-slate-600 w-full rounded-sm mb-3" />
            <div className="flex items-center justify-between mb-1">
              <p className="text-xs tracking-tight font-medium self-end">Initial Messages</p>
              <button onClick={() => setIntroMessageInput(DEFAULT_INTRO_MESSAGE)} className="h-min text-slate-500 bg-slate-100 rounded-md"><p className="p-1 text-xs">Reset</p></button>
            </div>
            <textarea rows={6} value={introMessageInput} onChange={(e) => setIntroMessageInput(e.target.value)} className="border border-slate-200 p-1.5 text-xs text-slate-600 w-full rounded-sm mb-1" />
            <p className="text-xs text-slate-400 mb-2">
              Enter each message in a new line.</p>
          </div>
          {deletionError && <p>Error deleting chatbot: {deletionError}</p>}
          {saveSettingsError && <p>Error saving chatbot settings: {saveSettingsError}</p>}
          <button onClick={saveChatbotSettings} className="flex justify-center h-min w-full px-2 py-3 shadow-lg mt-4 bg-black text-white text-md rounded-lg tracking-tight">
            {!saveChatbotSettingsLoading ? 'Save Changes' :
              <Oval
                height={20}
                width={20}
                color="white"
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="white"
                strokeWidth={4}
                strokeWidthSecondary={4}
              />}
          </button>
          <button onClick={deleteChatbot} className="flex justify-center h-min w-full px-2 py-3 shadow-lg mt-2 bg-red-600 text-white text-md rounded-lg tracking-tight">
            {!deleteChatbotInProgress ? 'Delete Chatbot' :
              <Oval
                height={20}
                width={20}
                color="white"
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="white"
                strokeWidth={4}
                strokeWidthSecondary={4}

              />}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ChatbotSettings