
import { useEffect, useRef} from 'react'
import { Dispatch, SetStateAction, useState } from 'react'
import "./EllipsisAnimation.css"
import { PaperAirplaneIcon } from '@heroicons/react/24/outline'
import { logEvent } from "firebase/analytics"
import { analytics } from '../Auth/firebase'
import { useUserValue } from '../Auth/UserContext'
import { BASE_FETCH_URL } from '../../App'
import { createStoredUUID, getStoredUUID } from '../Utils/Utils'


type Agent = 'bot' | 'user'

export type ChatMessage = {
  agent: Agent,
  content: string,
  inputType?: 'email' | 'text' | 'number' | 'nouse' | 'product_rec' // or any other input types you might need
  timestamp?: string;
}


type ChatbotChatboxProps = {
  chatbotId: string,
  chatboxHeight?: number
  displayName?: string
  introMessages: ChatMessage[]
  disableInputInteraction?: boolean
  setSource?: Dispatch<SetStateAction<ChatbotSource[] | null>>
  setRemainingMessagesCount?: Dispatch<SetStateAction<number | null>>
  userId?: string | null
  chatTopics?: string[] 
}

export type ChatbotSource = {
  query: string
  results: ChatbotSourceResult[]
}

type ChatbotSourceResult = {
  id: string
  embedding: any
  metadata: {
    author: string | null
    created_at: string | null
    document_id: string | null
    source: string | null
    source_id: string | null
    url: string | null
  }
  score: number
  text: string
}

type DecodedReadableStreamValue = {
  completion: string
  remaining_messages: number
  top_documents: ChatbotSource[]
  conversation_id: string
}

// const emailInputMessage: ChatMessage = {
//   agent: 'bot',
//   content: 'Please enter your email:',
//   inputType: 'email'
// }

// const productTileMessage: ChatMessage = {
//   agent: 'bot',
//   content: 'Definitely! Here is what we recommend:',
//   inputType: 'product_rec',
// }

export const fetchRemainingMessagesForNoAccountUser = async (setCount: Dispatch<SetStateAction<number | null>>) => {
  try {
    // create faux uuid on the frontend to use as non-account JWT
    const uuid = getStoredUUID() ?? createStoredUUID()
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${uuid}`,
      }
    }
    const response = await fetch(
      `${BASE_FETCH_URL}/api/v1/get-remaining-messages`,
      requestOptions
    )
    if (!response.ok) {
      throw new Error(response.status + ": " + response.statusText)
    }
    const responseData = await response.json()
    setCount(responseData.remaining_messages)
  } catch (error) {
    console.error("Error fetching remaining messages:", error)
  }
}

function ChatbotChatbox({
  chatbotId,
  introMessages,
  displayName = '',
  disableInputInteraction = false,
  setSource,
  setRemainingMessagesCount,
  userId = null,
  chatTopics = ["How can I build better habits?", "How can I improve self-confidence?","What is Mel's 5-second rule?"],
}: ChatbotChatboxProps) {

  // Initial product details
  // const initialProductDetails = {
  //   productTitle: 'Classic Red Dress',
  //   productDescription: 'A classic high-waisted look perfect for any occasion.',
  //   productRating: 4.9,
  //   productLink: 'https://firebasestorage.googleapis.com/v0/b/custom-gpt-ceb44.appspot.com/o/images%2Fred_dress_high_wait_vneck.jpeg?alt=media&token=246947fc-8087-4e0d-a0a3-dd0c4daeca7f',
  //   ratingsLink: 'https://firebasestorage.googleapis.com/v0/b/custom-gpt-ceb44.appspot.com/o/images%2Famazon_product_rating_49_left_space_removed.png?alt=media&token=b093e5e1-a01b-472a-a559-c14a66d8282c'
  // }

  const {userAuth, userSettings } = useUserValue()
  const [input, setInput] = useState('')
  const [chatbotResponseLoading, setChatbotResponseLoading] = useState(false)
  const [messages, setMessages] = useState<ChatMessage[]>(introMessages)
  // Set initial messages state including the first product tile
  // const [messages, setMessages] = useState<ChatMessage[]>([
  //   ...introMessages,
  //   { content: '', agent: 'bot', inputType: 'product_rec', ...initialProductDetails }
  // ])
  const [conversationId, setConversationId] = useState<string>('')
  const [showChatTopics, setShowChatTopics] = useState(true) // State to control the display of chat topics
  const messagesContainerRef = useRef<HTMLDivElement>(null) // Ref for the messages container

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages]) // Scroll to bottom every time messages update


  // Function to handle chat topic selection
  const handleChatTopicClick = (topic: string) => {
    sendMessage(topic)
    setShowChatTopics(false) // Hide chat topics after selection
  }

  useEffect(() => {
    // Hide chat topics after the user sends the first message
    if (messages.some(message => message.agent === 'user')) {
      setShowChatTopics(false)
    }
  }, [messages])

  useEffect(() => {
    // if we're within a UI where we display the # of message credits left, fetch remaining_messages for the initial display
    if (setRemainingMessagesCount) {
      if (userSettings) {
        setRemainingMessagesCount(userSettings.remainingMessages)
      } else {
        // user doesn't have an account/isn't logged in, use no-account uuid to fetch remaining_messages #
        fetchRemainingMessagesForNoAccountUser(setRemainingMessagesCount)
      }
    }
  }, [userSettings, setRemainingMessagesCount])


  useEffect(() => {
    if (disableInputInteraction) {
      // this this is true, the ChatbotChatbox is a demo display operating within the settings page.
      // allow messages array to be overwritten when introMessages are edited
      setMessages(introMessages)
    }
  }, [disableInputInteraction, introMessages])


  // useEffect(() => {
  //   const lastMessage = messages[messages.length - 1]
  //   if (lastMessage && lastMessage.agent === 'bot' && lastMessage.inputType !== 'email' && messages.length > 1) {
  //     setMessages((prevMessages) => [
  //       ...prevMessages,
  //       productTileMessage,
  //     ])
  //   }
  // }, [messages])

  // Fetch or create a new conversation ID
  useEffect(() => {
    const fetchOrCreateConversationId = async () => {
        // Check if there's an existing conversation ID in the local storage
        let currentConversationId = localStorage.getItem('conversationId')
        
        if (!currentConversationId) {
            // If not, create a new one
            // This could be a unique identifier created in your backend or frontend
            // For example, using a simple UUID or any unique string generation logic
            // const newConversationId = 'your-unique-id-generation-logic'; // Implement this logic
            // localStorage.setItem('conversationId', newConversationId);
            // currentConversationId = newConversationId;

            // For simplicity, setting it as an empty string if not found
            currentConversationId = ''
        }

        setConversationId(currentConversationId)
    }

    fetchOrCreateConversationId()
  }, [])

  

  const sendMessage = async (message: string) => {
    try {
      const updatedMessages: ChatMessage[] = [
        ...messages,
        { agent: 'user', content: message },
      ]

      // add new user message to chatbox display
      setMessages(updatedMessages)

      // show "typing" animation indicating that AI is responding
      setChatbotResponseLoading(true)

      const token = await userAuth?.getIdToken()
      const uuid = getStoredUUID()

      const headers: Record<string, string> = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
      }

      let endpoint: string | any 

      if (token) {
        endpoint = `${BASE_FETCH_URL}/api/v1/query-chatbot`
        headers['Authorization'] = `Bearer ${token}`
      } else if (uuid) {
        endpoint = `${BASE_FETCH_URL}/api/v1/query-chatbot-noaccount`
        headers['Authorization'] = `Bearer ${uuid}`
      } else if (userId) {
        endpoint = `${BASE_FETCH_URL}/api/v1/query-chatbot-public`
        headers['Authorization'] = `Bearer ${userId}`
      }
      // Create message timestamp
      const timestamp = new Date().toISOString()

      const res = await fetch(endpoint, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          chatbotId: chatbotId,
          conversationId: conversationId,
          query: message,
          timestamp: timestamp
        })
      })

      setChatbotResponseLoading(false)

      if (!res.ok) {
        throw new Error(res.status + ": " + res.statusText)
      }

      // data is a ReadableStream
      const data = res.body
      if (!data) {
        throw new Error("No response received.")
      }

      readStream(data, updatedMessages)

      // const lastBotMessage = updatedMessages[updatedMessages.length - 1]
      // if (lastBotMessage.agent === 'bot' && lastBotMessage.inputType !== 'email') {
      //   setMessages((prevMessages) => [
      //     ...prevMessages,
      //     productTileMessage,
      //   ])
      // }

      // log analytics event
      logEvent(analytics, 'success_query_chatbot')

    } catch (e: any) {
      setChatbotResponseLoading(false)

      let errorMessage = ''
      if (e instanceof Error) {
        // More specific error message if it's an instance of Error
        errorMessage += ` Error: ${e.message}`
      } else if (typeof e === 'string') {
        // Error is a string
        errorMessage += ` Error: ${e}`
      } else {
        // Other types of errors
        errorMessage += ' Please try again later.'
      }
      console.log(`errorMessage: ${errorMessage}`)

      setMessages((m) => {
        const newMessageIndex = m.length
        const newHistory = [...m]
        newHistory[newMessageIndex] = {
          agent: 'bot',
          content: `Uh oh, something went wrong. There was an error with the chatbot response.`
        }
        return newHistory
      })
      // log analytics event
      logEvent(analytics, 'failure_query_chatbot')
    }
  }

  async function readStream(stream: ReadableStream, updatedMessages: ChatMessage[]) {
    let textResponseStream = ""
    const reader = stream.getReader()
    const decoder = new TextDecoder()
    let doneReading = false


    while (!doneReading) {
      // The `read()` method returns a promise that
      // resolves when a value has been received.
      const { done, value } = await reader.read()
      // Result objects contain two properties:
      // `done`  - `true` if the stream has already given you all its data.
      // `value` - Some data. Always `undefined` when `done` is `true`.

      if (done) {
        doneReading = true
        return
      }

      const decodedValue: DecodedReadableStreamValue = JSON.parse(decoder.decode(value))

      const source = decodedValue.top_documents
      const messagesLeftCount = decodedValue.remaining_messages
      const text = decodedValue.completion
      const conversation_id = decodedValue.conversation_id
      setConversationId(conversation_id)

      if (setSource) {
        setSource(source)
      }

      if (setRemainingMessagesCount && messagesLeftCount) {
        setRemainingMessagesCount(messagesLeftCount)
      }

      textResponseStream += text

      // todo don't need to rewrite entire message state, instead just manipulate latest message
      const chatbotResponseIndex = updatedMessages.length
      const messageHistory = [...updatedMessages]
      messageHistory[chatbotResponseIndex] = { agent: 'bot', content: textResponseStream }

      setMessages(messageHistory)
    }
  }

  return (
    <div className="flex flex-col h-full justify-between">
      <div className="overflow-scroll" ref={messagesContainerRef}>
      {displayName ? (
    <div className="sticky flex rounded-t-lg w-full top-0 border-b-1 border-b border-b-slate-200 bg-white items-center pl-3 mr-0 pr-0">
        <img src={"https://firebasestorage.googleapis.com/v0/b/custom-gpt-ceb44.appspot.com/o/images%2Fkandy_saas_logo_2.png?alt=media&token=5e2b9c06-734b-4a0b-a4ea-f179bce09ed2"} 
             alt="Icon" 
             className="h-6 w-6 my-2 ml-2 mr-0" />
        <p className="px-2 py-2 font-medium text-sm ml-0 pl-0">{displayName}</p>
    </div>
) : null}
        <div className="flex flex-col pt-2 px-1">
        {
                messages.map(({ content, agent, inputType }, index) => {
                  if (inputType === 'nouse') {
                    return <EmailInputField key={index} sendMessage={sendMessage} agent={agent} chatbotId={chatbotId} />
                  // } else if (inputType === 'product_rec' && index === 3) { // Only the first two product recommendations will be displayed
                  //   // These details should come from your actual product data
                  //   const productDetails = {
                  //     productTitle: 'Red Summer Dress',
                  //     productDescription: 'The fun flowery summer look you\'ve been looking for.',
                  //     productRating: 4.9,
                  //     productLink: 'https://firebasestorage.googleapis.com/v0/b/custom-gpt-ceb44.appspot.com/o/images%2Fred_dress_summer_2.jpg?alt=media&token=1e3d88da-97a2-4572-806d-d91cf3ba5c9c',
                  //     ratingsLink: 'https://firebasestorage.googleapis.com/v0/b/custom-gpt-ceb44.appspot.com/o/images%2Famazon_product_rating_48_left_space_removed_2.png?alt=media&token=3c8350be-9936-4074-aebf-72145158518a'
                  //   }
                  //   return <ProductTileField key={index} {...productDetails} />
                  // } else if (inputType === 'product_rec' && index === 4) { // Only the first two product recommendations will be displayed
                  //   // These details should come from your actual product data
                  //   const productDetails = {
                  //     productTitle: 'Classic Long Dress',
                  //     productDescription: 'A classic high-waisted look perfect for any occasion.',
                  //     productRating: 4.8,
                  //     productLink: 'https://firebasestorage.googleapis.com/v0/b/custom-gpt-ceb44.appspot.com/o/images%2Fred_dress_high_wait_vneck.jpeg?alt=media&token=246947fc-8087-4e0d-a0a3-dd0c4daeca7f', 
                  //     ratingsLink: 'https://firebasestorage.googleapis.com/v0/b/custom-gpt-ceb44.appspot.com/o/images%2Famazon_product_rating_48_left_space_removed.png?alt=media&token=1dd237d7-76e8-4e31-a684-c86008670c14'
                  //   }
                  //   return <ProductTileField key={index} {...productDetails} />
                  } else if (inputType !== 'product_rec') {
                    return <MessageBubble key={index} agent={agent} content={content} />
                  }
                  return null // When inputType is 'product_rec' but index is 2 or more, don't render anything
                })
              }
          {/* Render chat topic bubbles if showChatTopics is true */}
          {showChatTopics && messages.length === 1 && messages[0].agent === 'bot' && (
  <div className="flex flex-wrap p-1" style={{ gap: '2px' }}>
    {chatTopics.map((topic, index) => {
  return (
    <button
      key={index}
      onClick={() => handleChatTopicClick(topic)}
      className="text-sm"
      style={{
        backgroundColor: '#f1f5f9', // Light blue-grayish background
        color: 'black', // White text
        borderWidth: '1px', // 1px black border
        borderColor: 'black',
        margin: '5px',
        borderRadius: '20px',
        padding: '3px 12px',
      }} 
    >
      {topic}
    </button>
  )   
})}
          </div>
        )}

          <div className="block">
            {chatbotResponseLoading ? <div className="block h-9 w-16 rounded-lg mb-2 bg-slate-100">
              <div className="dot-flashing" />
            </div> : null}
          </div>
        </div>
        {/* <div ref={messagesEndRef} /> Empty div for auto-scroll reference */}
      </div>

      <ChatbotInput
        disableInputInteraction={disableInputInteraction}
        input={input}
        setInput={setInput}
        sendMessage={sendMessage}
      />
    </div>
)
}

export default ChatbotChatbox


const ChatbotInput = ({ input, setInput, sendMessage, disableInputInteraction }: any) => (
  <div className={`flex items-center mx-2 mb-3 border border-slate-300 rounded-md`}>
    <input
      type="text"
      disabled={disableInputInteraction}
      aria-label="chat input"
      className="w-full p-2  bg-white rounded-md text-sm"
      placeholder={disableInputInteraction ? undefined : "Type here..."}
      value={input}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          sendMessage(input)
          setInput('')
        }
      }}
      onChange={(e) => {
        setInput(e.target.value)
      }}
    />
    <button
      className="h-full px-2"
      disabled={disableInputInteraction}
      type="submit"
      onClick={() => {
        sendMessage(input)
        setInput('')
      }}>
      <PaperAirplaneIcon className="h-6 w-6 text-slate-500"
      />
    </button>
  </div>
)

function MessageBubble({ agent, content }: { agent: string; content: string }) {
  if (!content) {
    return null
  }

  // This function creates an object with a __html property with the given value
  const createMarkup = (htmlContent: string) => {
    return { __html: htmlContent }
  }

  return (
    <div className={`max-w-[60%] rounded-lg mb-2 ${agent === 'bot' ? 'self-start bg-slate-100' : 'self-end text-white bg-accent-blue'}`}>
      {/* // TODO: Add appropriate time annotations
      // TODO: Adjust margins (less margin for two messages in a row from same agent) */}
      {/* Use dangerouslySetInnerHTML to set the content as HTML */}
      <p className="text-sm px-4 py-2" dangerouslySetInnerHTML={createMarkup(content)}></p>
    </div>
  )
}

function EmailInputField({ sendMessage, agent, chatbotId }: { sendMessage: (message: string) => void, agent: Agent, chatbotId: string }) {

  const { userAuth } = useUserValue()
  const [email, setEmail] = useState('')

  const submitEmail = async () => {
    try {
      const token = await userAuth?.getIdToken()
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          chatbotId: chatbotId,
          emails: [email],
        }),
      }
      const response = await fetch(`${BASE_FETCH_URL}/api/v1/update-chatbot-emails`, requestOptions)
      console.log(`${response}`)
  
      // existing code
      logEvent(analytics, 'success_submit_email')

    } catch (error) {
      console.error('Failed to submit email:', error)
      sendMessage(`Failed to submit email. Please try again later.`)
    }
  }

  return (
    <div className={`max-w-[60%] rounded-lg mb-2 ${agent === 'bot' ? 'self-start bg-slate-100' : 'self-end text-white bg-accent-blue'}`}>
      <div className="flex flex-col items-start p-2">
        {agent === 'bot' && (
          <p className="text-sm mb-4 bg-slate-100 px-2" style={{ textAlign: 'left', width: '100%' }}>
            Let us know how to contact you:
          </p>
        )}
        <div className="flex items-center w-full">
          <input
            type="email"
            className="text-sm flex-1 p-2 rounded-md border border-slate-300"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button
            className="ml-2 px-4 py-2 rounded-md bg-accent-blue text-white"
            onClick={submitEmail}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  )  
}


// type ProductTileFieldProps = {
//   productTitle: string,
//   productDescription: string,
//   productLink: string,
//   ratingsLink: string
// }

// Component to render product recommendations
// function ProductTileField({ productTitle,  productLink, productDescription, ratingsLink }: ProductTileFieldProps) {
//   const smallImageLink = 'https://firebasestorage.googleapis.com/v0/b/custom-gpt-ceb44.appspot.com/o/images%2Ficons8-new-tab-24.png?alt=media&token=ce2ef72a-1736-4e7b-8cd9-27ac50db9d32'
//   // const newSmallImageLink = 'https://firebasestorage.googleapis.com/v0/b/custom-gpt-ceb44.appspot.com/o/images%2Famazon_product_rating_48.png?alt=media&token=be843496-e2f8-43ac-921c-077827fa07bf'

//   return (
//     <div className="max-w-[60%] self-start bg-white rounded-lg mb-3 border border-black" style={{maxHeight: '68px', borderWidth: "2px", width: "415px"}}> 
//       <div className="flex items-start rounded-lg"> {/* Add padding and rounded corners */}
//         <img
//           src={productLink}
//           alt={productTitle}
//           className="object-cover flex-none"
//           style={{ 
//             maxHeight: '64px', 
//             minHeight: '64px', 
//             height: 'auto', 
//             width: 'auto', 
//             top: '-2px', 
//             left: '-2px', 
//             borderTopLeftRadius: '0.3rem', 
//             borderBottomLeftRadius: '0.3rem',
//             zIndex: '10' // Ensure it's above the border
//           }} 
//         />
//         <div className="flex flex-col pl-3 ml-3 w-full">
//           <div className="flex items-center">
//             <span className="font-bold mr-2">{productTitle}</span>
//             <img
//               src={ratingsLink}
//               alt=""
//               style={{ width: '87px', height: '15px', marginRight: '5px', marginLeft: '4px'}}
//             />
//             <img
//               src={smallImageLink}
//               alt=""
//               style={{ width: '15px', height: '15px', marginLeft: '60px'}} 
//             />
//           </div>
//           <span className="text-black tracking-tight text-xs pb-1">{productDescription}</span>
//           <span className="italic text-black text-xxs">
//             Browse <span className="text-blue underline">more products</span> like this</span>
//         </div>
//       </div>
//     </div>
//   )
// }