const LOCAL_STORAGE_KEY = "chatq-token"

export function generateUUID(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0
    const v = c === 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

export function createStoredUUID(): string {
  const uuid: string = generateUUID()
  localStorage.setItem(LOCAL_STORAGE_KEY, uuid)
  return uuid
}

export function getStoredUUID(): string | null {
  return localStorage.getItem(LOCAL_STORAGE_KEY)
}