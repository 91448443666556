import { User } from 'firebase/auth'
import { useContext, createContext, ReactNode } from 'react'

const defaultUserProviderValues: UserProviderValues = {
  userAuth: null,
  userSettings: null
}

const UserContext = createContext<UserProviderValues>(defaultUserProviderValues)

type UserProviderProps = {
  children: ReactNode
  value: UserProviderValues
}

type UserProviderValues = {
  userAuth: UserAuth
  userSettings: UserSettings
}

export type UserSettings = {
  remainingMessages: number
  pricingId: string
  pricingTier: string
  maxChatbots: number
} | null

type UserAuth = User | null

export function UserProvider({ children, value }: UserProviderProps) {
  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  )
}

export function useUserValue() {
  return useContext(UserContext)
}
