import { useContext, createContext, ReactNode, Dispatch, SetStateAction } from 'react'
import { Chatbot } from './MyChatbots'

type MyChatbotsValueType = [Chatbot[], Dispatch<SetStateAction<Chatbot[]>>]

const defaultMyChatbotsValue: MyChatbotsValueType = [[], () => null]

const MyChatbotsContext = createContext(defaultMyChatbotsValue)

type MyChatbotsProviderProps = {
  children: ReactNode
  value: MyChatbotsValueType
}

export function MyChatbotsProvider({ children, value }: MyChatbotsProviderProps) {
  return (
    <MyChatbotsContext.Provider value={value}>
      {children}
    </MyChatbotsContext.Provider>
  )
}

export function useMyChatbotsValue() {
  return useContext(MyChatbotsContext)
}
