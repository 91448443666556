import { Link } from "react-router-dom"

const linkedinSvgPath: string = require('./socialIcons/LinkedinIcon.svg').default
const twitterSvgPath: string = require('./socialIcons/TwitterIcon.svg').default

const NavigationFooter = () => (

  <div className="rounded-t-sm bg-gradient-to-tl from-accent-turquoise to-accent-blue px-4">
    <div className="flex flex-col items-end text-right text-white">

      <p className="text-md tracking-tight py-6">ChatQ</p>
      <p className="pb-1 font-medium text-xs"><Link to="/privacy-policy">Privacy Policy</Link></p>
      <p className="pb-6 font-medium text-xs"><Link to="/terms-of-service">Terms of Service</Link></p>
      <div className="flex pb-6">
        <a target="_blank" href="https://www.linkedin.com/company/chatter-co/" rel="noreferrer"><img src={linkedinSvgPath} className="h-6 w-6 mr-2" alt="linkedin-logo" /></a>
        <a target="_blank" href="https://twitter.com" rel="noreferrer"><img src={twitterSvgPath} className="h-6 w-6 mr-2" alt="twitter-logo" /></a>
      </div>
    </div>
    {/* <div className="w-full border-b border-b-white " />
    <p className="text-sm tracking-tight pt-6 pb-2 text-white">Questions or feedback?</p>
    <p className="text-sm tracking-tight pb-6 text-white">hello@chatq.co</p> */}
  </div>
)

export default NavigationFooter