import React, { useState } from "react"

import { Oval } from "react-loader-spinner"
import { logEvent } from "firebase/analytics"
import { analytics } from '../Auth/firebase'
import { useUserValue } from "../Auth/UserContext"
import { BASE_FETCH_URL } from "../../App"
import { Chatbot } from "../MyChatbots/MyChatbots"
import { DataImportProps } from "./CreateNewChatbot"


const FileImport = ({ goBack, navigateToChatbot }: DataImportProps) => {
  const { userAuth } = useUserValue()
  const [fileToUpload, setFileToUpload] = useState<File | undefined>()
  const [error, setError] = useState<Error | null>(null)
  const [submitInProgress, setSubmitInProgress] = useState(false)

  // Handle file upload event and update state
  async function handleFileUpload(event: React.ChangeEvent<HTMLInputElement>) {
    const fileEvent = event.target.files?.length ? event.target?.files[0] : undefined
    if (fileEvent) {
      setFileToUpload(fileEvent)
    }
  }

  async function handleCreateChatbot(f: File) {
    setSubmitInProgress(true)
    try {
      const token = await userAuth?.getIdToken()
      const formData = new FormData()
      formData.append('file', f)

      const response = await fetch(`${BASE_FETCH_URL}/api/v1/create-chatbot-file`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData
      })

      if (response.ok) {
        const jsonResponse = await response.json()
        const newChatbot: Chatbot = {
          name: jsonResponse.chatbotName,
          id: jsonResponse.chatbotId,
          image: jsonResponse.chatbotImage
        }
        logEvent(analytics, 'success_create_chatbot_file')
        navigateToChatbot(newChatbot.id)
      } else {
        setSubmitInProgress(false)
        if (response.status === 401) {
          throw new Error(`${response.status} ${response.statusText}: Please log in first.`)
        }
        logEvent(analytics, 'failure_create_chatbot_file')
        throw new Error(response.status + " " + response.statusText)
      }

    } catch (e: any) {
      setError(e)
      setSubmitInProgress(false)
    }

  }

  return (
    <div className="flex flex-col max-w-lg w-full items-center">
      <button onClick={goBack} className="h-min self-start text-slate-500 bg-slate-100 rounded-lg mb-2"><p className="py-1.5 px-2 text-xs">&larr; back</p></button>
      <div className="flex flex-col max-w-sm w-full">

        <h1 className="font-medium mb-6 self-center">Upload File</h1>
        {/* <p className="mb-6 text-sm text-slate-500 text-center">Attach a file to see how many characters are in it.</p> */}
        {/* // TODO count file char count &  display */}
        <input className="text-sm bg-slate-50 border rounded-sm border-slate-300 mb-6" type="file" onChange={handleFileUpload} />
        {/* <p className="text-sm text-center text-slate-500">Note: uploading a PDF using Safari doesn&apos;t work. We&apos;re looking into the issue.
          Make sure the text is OCR&apos;d, i.e. you can highlight and copy it within the document file.</p> */}
        <button onClick={() => handleCreateChatbot(fileToUpload!)} disabled={!fileToUpload || submitInProgress} className={`${!fileToUpload ? "opacity-40" : ""} flex justify-center bg-black self-center text-white mt-12 py-2 w-full rounded-lg shadow-lg text-md tracking-tight`}>{submitInProgress ? <Oval
          height={20}
          width={20}
          color="white"
          visible={true}
          ariaLabel='oval-loading'
          secondaryColor="white"
          strokeWidth={4}
          strokeWidthSecondary={4}
        /> : 'Create Chatbot'}</button>
        {error && <p className="text-sm pt-4 text-red-600 text-medium text-center">Something went wrong{error.message ? ': ' + error.message : '.'}</p>}
      </div>
    </div>
  )
}

export default FileImport